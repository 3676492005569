import {
    Col,
    Content,
    DropDown,
    Layout,
    Page,
    Row,
    Paragraph,
    Space,
    Spacer,
    Title,
    Divider,
    Button, Card, Input, Link
} from "../layout/Content";
import React from "react";
import {Footer} from "../part/Footer";
import {Header} from "../part/Header";

export function NotFound() {
    return (<Layout className="landing-page">
        <Header/>
        <Page Grow>
            <Content style={{height: "100%"}}>
                <div className={"limits pad"}>
                    <Row>
                        <Col xs={24}>
                            <Space direction="vertical" Gap>

                                <Title Large>404 Not Found</Title>
                                <h2>Page Not Found</h2>
                                <p>The page you are looking for doesn’t exist or another error occurred.</p>
                                <p><Link href="/">Go back to the homepage</Link></p>

                            </Space></Col></Row>
                </div>
            </Content>
        </Page>
        <Footer/>
    </Layout>)
}