import React, {useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Card, Col, Content, DropDown, Layout, Page, Row, Space, Title} from '../layout/Content';
import {uploadChunk, UploadProgress} from "../../lib/FileUploadWithProgress";
import {Header} from "./Header";
import {Footer} from "./Footer";
// @ts-ignore
import MarkdownRenderer from 'react-markdown-renderer';
import {Tooltip} from "../layout/Defaults";

interface FormCommunityProps {
}


interface ValidationResult {
    result: boolean;
    fields: any[];
}

const FormCommunity: React.FC<FormCommunityProps> = () => {
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const navigate = useNavigate();

    const [preview, setPreview] = useState<boolean>(false);


    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(e.target.value);
    };

    const checkValidation = (): ValidationResult => {
        let valid = true;
        const fields = [
            {
                name: "name", result: () => {
                    return (name.length >= 3 && name.length <= 128)
                }, fail_message: "Name must be between 3-128 Characters"
            },
            {
                name: "description", result: () => {
                    return (description.length >= 3 && description.length <= 128)
                }, fail_message: "Description must be between 3-128 Characters"
            },
        ];
        const result: any = {result: true, fields: []};

        fields.forEach((field) => {
            if (!field.result()) {
                valid = false;
                if (result.fields && field) {
                    result.fields.push(field);
                }
            }
        });
        result.result = valid;
        return result;
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        //e.preventDefault();

        const postData = {
            name,
            description,
            // owner: 'owner-uuid', // Replace this with actual owner UUID
        };

        console.log(postData);

        try {
            const response = await fetch('https://api.peek.blog/community', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include', // This tells the browser to include cookies with the request
                body: JSON.stringify(postData),
            });

            if (!response.ok) {
                throw new Error('Failed to create Community');
            }

            const result = await response.json();
            console.log('Community created successfully:', result);
            setName('');
            setDescription('');
            navigate("/c/" + result.results[0].uuid);
        } catch (error) {
            console.error('Error creating post:', error);
        }
    };

    const getButton = () => {

        return (<Button type={checkValidation().result ? "primary" : "disabled"} onClick={(e: any) => {
            if (checkValidation().result) {
                handleSubmit(e);
            }
        }}>Create Community</Button>);

    }

    return (<Layout className="landing-page">
        <Header/>
        <Page Grow>
            <Content style={{height: "100%"}}>
                <div className={"limits wide pad"} style={{height: "100%"}}>
                    <Row Gap Fill style={{height: "100%"}}>

                        <Col xs={24}>

                            <Space direction={"vertical"} Gap>
                                <Title Large>Create a new Community</Title>
                                <Space direction={"vertical"} GapSm>
                                    <label htmlFor="title">Name</label>
                                    <input
                                        type="text"
                                        id="title"
                                        value={name}
                                        placeholder={"Community Name"}
                                        onChange={handleTitleChange}
                                        required
                                    />
                                    <textarea onChange={handleDescriptionChange}
                                              placeholder={"Community Description"}></textarea>

                                </Space>

                                <div>
                                <Space>
                                    {checkValidation().result ? getButton() :
                                        <Tooltip message={checkValidation().fields.map((field) => {
                                            return <p>{field.fail_message}</p>;
                                        })}>{getButton()}</Tooltip>}
                                </Space>
                                </div>
                            </Space>

                        </Col>

                    </Row>
                </div>
            </Content>
        </Page>
        <Footer/>
    </Layout>);
};

export default FormCommunity;
