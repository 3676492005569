import React, {useEffect, useState} from 'react';
import './App.scss';
import {BrowserRouter, Route, Router, Routes} from "react-router-dom";
import {Landing} from "./component/page/Landing";
import {StyleGuide} from './component/page/StyleGuide';
import {PrivacyPolicy} from "./component/page/PrivacyPolicy";
import {CookiePolicy} from "./component/page/CookiePolicy";
import {TermsOfService} from "./component/page/TermsOfService";
import {Features} from "./component/page/Features";
import {Partner} from "./component/page/Partner";
import {getPlatform, getWrapper} from "./lib/Wrapper";
import {GenericManager} from "./component/part/GenericManager";
import {UserProfileProvider, useUserProfileContext} from "./lib/UserContext";
import {Feed} from "./component/page/Feed";
import {NotFound} from "./component/page/NotFound";
import {PhotoEditingPage} from "./component/page/Editor";
import UserManager from "./component/part/UserManager";
import CollectionManager from "./component/part/CollectionManager";
import FormPost from "./component/part/PostForm";
import PostViewer from "./component/page/PostViewer";
import FormCommunity from "./component/part/FormCommunity";
import UserProfileViewer from "./component/page/UserViewer";
import CommunityViewer from "./component/page/CommunityViewer";
import UserSettings from "./component/page/UserSettings";
import {Modal} from "./component/layout/Defaults";
import {Gallery} from "./component/page/Gallery";

const PageComponent = (props: { element: any, before?: any }) => {
    const {queryUserProfile, getUserProfile, hasQueriedUserProfile} = useUserProfileContext();
    const [modal, setModal] = useState<number>(1);


    const accountSetupModal = () => {
        if (!hasQueriedUserProfile()) {
            return false;
        }
        const user = getUserProfile();
        if (user && ((Date.now() - modal) > 5*60*1000)) {
            if (user.attributes && user.attributes.userDetails) {

            } else {
                setModal(0);
            }
        }
    }


    useEffect(() => {
        //  document.addEventListener("mousedown", downHandler);
        // document.addEventListener("mouseup", upHandler);
        //fetchUserProfile();
        const userRefresh = setInterval(() => {
            //     fetchUserProfile();
        }, 8000);

        return () => {
            // document.removeEventListener("mousedown", downHandler);
            //document.removeEventListener("mouseup", upHandler);
            clearInterval(userRefresh)
        }
    }, []);

    const defer = async () => {
        await queryUserProfile();
        accountSetupModal();
    }


    //const [top, setTop] = useState(0);
    useEffect(() => {
        defer();
        // track pageview with gtag / react-ga / react-ga4, for example:
        console.log(window.location.pathname);
        //ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search});

        // @ts-ignore
        //document.getElementById("scroll-container").scrollTo(0, 0);
    }, [window.location, props]);


    return (<div>
        {props.element}

        <Modal visible={modal===0} title={<span>Finalize your Account</span>}
               content="This is an example modal." onClose={() => {
            console.log('Modal closed');
            setModal(Date.now());
        }}/>
    </div>)
}

function App() {

    return (
        <div className={["App", getPlatform(), getWrapper()].join(" ")}>
            <UserProfileProvider>
                <BrowserRouter>
                    <Routes>
                        {/* Core Pages */}
                        <Route path="/" element={<PageComponent element={<Landing/>}/>}/>

                        <Route path="/feed" element={<PageComponent element={<Feed/>}/>}/>

                        <Route path="/style" element={<PageComponent element={<StyleGuide/>}/>}/>

                        <Route path="/peek" element={<PageComponent element={<Features/>}/>}/>
                        <Route path="/partner" element={<PageComponent element={<Partner/>}/>}/>

                        <Route path="/admin" element={<PageComponent element={<CollectionManager/>}/>}/>

                        <Route path="/admin/data" element={<PageComponent element={<GenericManager/>}/>}/>
                        <Route path="/admin/user" element={<PageComponent element={<UserManager/>}/>}/>

                        <Route path="/test" element={<PageComponent element={<PhotoEditingPage/>}/>}/>

                        <Route path="/gallery" element={<PageComponent element={<Gallery/>}/>}/>

                        <Route path="/post" element={<PageComponent element={<FormPost/>}/>}/>
                        <Route path="/p/:uuid" element={<PageComponent element={<PostViewer/>}/>}/>
                        <Route path="/u/:uuid" element={<PageComponent element={<UserProfileViewer/>}/>}/>
                        <Route path="/c/:uuid" element={<PageComponent element={<CommunityViewer/>}/>}/>


                        <Route path="/community" element={<PageComponent element={<FormCommunity/>}/>}/>

                        <Route path="/settings" element={<PageComponent element={<UserSettings/>}/>}/>


                        <Route path="/info/privacy-policy" element={<PageComponent element={<PrivacyPolicy/>}/>}/>
                        <Route path="/info/terms-of-service" element={<PageComponent element={<TermsOfService/>}/>}/>
                        <Route path="/info/cookie-policy" element={<PageComponent element={<CookiePolicy/>}/>}/>

                        <Route path="*" element={<PageComponent element={<NotFound/>}/>}/>

                    </Routes>
                </BrowserRouter>
            </UserProfileProvider>

        </div>
    );
}

export default App;
