import {
    Col,
    Content,
    DropDown,
    Layout,
    Page,
    Row,
    Paragraph,
    Space,
    Spacer,
    Title,
    Divider,
    Button, Card, Input
} from "../layout/Content";
import React from "react";
import {Footer} from "../part/Footer";
import {Header} from "../part/Header";

export function PrivacyPolicy() {
    return (<Layout className="landing-page">
        <Header/>
        <Page Grow>
            <Content style={{height: "100%"}}>
                <div className={"limits pad"}>

                <Row>
                    <Col xs={24}>
                        <Space direction="vertical" Gap>
                            <Title Large>Privacy Policy</Title>
                            <p>Welcome to the Peek. This Privacy Policy describes how the Peek Group Ltd. ("we", "our", "us") collects, uses, and discloses your information when you use our online art gallery and social photo sharing platform ("Peek").</p>
                            <ul>
                                <li>We collect personal information such as your name, email address, and any other information you provide when you create an account, upload content, or contact us.</li>
                                <li>We use this information to provide and improve our services, communicate with you, and ensure the security of our platform.</li>
                                <li>We do not share your personal information with third parties except as necessary to provide our services or as required by law.</li>
                                <li>We use cookies to enhance your experience on our website. You can manage your cookie preferences through your browser settings.</li>
                                <li>You have the right to access, correct, or delete your personal information. Please contact us at privacy@thepeek.com for any privacy-related concerns.</li>
                            </ul>
                        </Space>
                    </Col>
                </Row>
                </div>
            </Content>
        </Page>
        <Footer/>
    </Layout>)
}