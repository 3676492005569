import {Button, Col, Divider, DropDown, Link, Row, Space, Spacer, Title} from "../layout/Content";
import React from "react";
import { useUserProfileContext} from "../../lib/UserContext";
import {IconTechnologyKeyboard} from "../../lib/Icon";
import {InlineIcon, InlineImage} from "../layout/Defaults";
import {Trademark} from "./Trademark";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog, faUserCheck, faUserPlus} from "@fortawesome/free-solid-svg-icons";
import {LoginModal} from "./LoginModal";
import {faCircleUser, faUser} from "@fortawesome/free-regular-svg-icons";
import Badge from "./Badge";

export function Header() {

    const { queryUserProfile, getUserProfile, hasQueriedUserProfile } = useUserProfileContext();

    const accountSetupCheckNotif = () => {
        if (!hasQueriedUserProfile()) {
            return false;
        }
        const user = getUserProfile();
        if (user) {
            if (user.attributes && !user.attributes.userDetails) {
                return true;
            }
        }
        return false;
    }

    return (<header className={"banner"}>
        <Row>
            <Col xs={24}>
                <div className={"Limits"}>
                <Space direction={"vertical"} Gap>
                    <Space align={"center"} justify={"space-between"}>

                        <Link href={"/"}>
                           <Title Large><InlineImage src={"/eye192.png"}/></Title>
                            </Link>
                        <Link span href={"/feed"}><Space GapSm><Title Large className={"Invert PadH"}><Space GapSm><span className={"Muted"} style={{fontSize:"60%"}}>the</span> <span >Peek</span></Space></Title><Trademark/></Space></Link>


                        <Space GapSm>
                            {!getUserProfile()&&<LoginModal defaultMode={true}><Button type={"default"}><FontAwesomeIcon fixedWidth icon={faUserPlus} /><span className={"hide-sm"}> Sign up</span></Button></LoginModal>}
                        <DropDown>
                            <Space direction={"vertical"} GapSm>
                                <div><Link href={"/"}>home</Link></div>
                                {getUserProfile()&&<div><Link href={"/feed"}>feed</Link></div>}
                                {getUserProfile()&&<div><Link href={"/post"}>post</Link></div>}
                                {getUserProfile()&&<div><Link href={"/gallery"}>gallery</Link></div>}
                                <div><Link href={"/peek"}>peek</Link></div>
                                <div><Link href={"/partner"}>partner</Link></div>
                                {!getUserProfile()&&<div><Divider/></div>}
                                {!getUserProfile()&&<LoginModal defaultMode={true}><FontAwesomeIcon fixedWidth icon={faUserPlus} /> <a href={"#"}>Sign up</a></LoginModal>}
                                {!getUserProfile()&&<LoginModal defaultMode={false}><FontAwesomeIcon className={"Muted"} fixedWidth icon={faUserCheck} /> <a href={"#"}>Log in</a></LoginModal>}
                                {getUserProfile()&&<div><Divider/></div>}
                                {getUserProfile()&&<div>
                                    {
                                        accountSetupCheckNotif() ?
                                            <Badge circle data={""}><Link href={"/settings"}><FontAwesomeIcon fixedWidth icon={faCog}/> settings</Link></Badge>
                                            :
                                        <Link href={"/settings"}><FontAwesomeIcon fixedWidth icon={faCog}/> settings</Link>
                                    }
                                </div>}
                                {getUserProfile()&&<div><Divider/></div>}
                                {getUserProfile()&&<div><a href={"/auth/logout"}>sign out</a></div>}
                            </Space>
                        </DropDown>
                        </Space>
                    </Space>
                </Space>
                </div>
            </Col>

        </Row></header>);
}