import React, {useEffect, useState} from 'react';
import {
    Col, Content, Layout, Page, Row, Space, Title, Divider, Button, Card
} from "../layout/Content";
import {Footer} from "./Footer";
import {Header} from "./Header";

// Define interfaces for API response and item structure
interface Item {
    _id: string;
    name: string;
    uuid:string;
    description: string;
}

interface QueryResult<T> {
    results: T[];
    query: {
        total_items: number;
        page: {
            index: number;
            size: number;
        };
        timing: number;
    };
}

const API_URL = '//api.peek.blog/data';

export const GenericManager: React.FC = () => {
    const [items, setItems] = useState<Item[]>([]);
    const [newItem, setNewItem] = useState({name: '', description: ''});
    const [filter, setFilter] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [pageSize] = useState<number>(5); // Fixed page size

    // Fetch items from API
    const fetchItems = async (filter: string, pageIndex: number, pageSize: number): Promise<void> => {
        setLoading(true);
        try {
            const response = await fetch(`${API_URL}?filter=${filter}&page=${pageIndex}&size=${pageSize}`);
            const result: QueryResult<Item> = await response.json();
            setItems(result.results);
        } catch (error) {
            console.error('Error fetching items:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleAdd = async () => {
        setLoading(true);
        try {
            const payload = {};
            const response = await fetch(`${API_URL}?filter=${filter}&page=${pageIndex}&size=${pageSize}`);
            const result: QueryResult<Item> = await response.json();
            setItems(result.results);
        } catch (error) {
            console.error('Error fetching items:', error);
        } finally {
            setLoading(false);
        }

    };

    // Handle form submission to create new item
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        try {
            const response = await fetch(API_URL, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                credentials: 'include', // This tells the browser to include cookies with the request
                body: JSON.stringify(newItem)
            });
            if (response.ok) {
                setNewItem({name: '', description: ''});
                fetchItems(filter, pageIndex, pageSize);
            }
        } catch (error) {
            console.error('Error creating item:', error);
        }
    };

    // Handle item deletion
    const handleDelete = async (id: string): Promise<void> => {
        try {
            const response = await fetch(`${API_URL}/${id}`, {
                method: 'DELETE'
            });
            if (response.ok) {
                fetchItems(filter, pageIndex, pageSize);
            }
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    // Handle item update
    const handleUpdate = async (id: string, updatedItem: Partial<Item>): Promise<void> => {
        try {
            const response = await fetch(`${API_URL}/${id}`, {
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                credentials: 'include', // This tells the browser to include cookies with the request
                body: JSON.stringify(updatedItem)
            });
            if (response.ok) {
                fetchItems(filter, pageIndex, pageSize);
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };

    // Fetch items on component mount and filter change
    useEffect(() => {
        fetchItems(filter, pageIndex, pageSize);
    }, [filter, pageIndex]);

    return (
        <Layout className="item-manager">
            <Header/>
            <Page Grow>
                <Content style={{height: "100%"}}>
                    <div className={"limits pad"}>
                        <Row>
                            <Col xs={24}>
                                <Space direction="vertical" Gap>
                                    <Title Large>Item Manager</Title>
                                    <Divider/>
                                    <form onSubmit={handleSubmit}>
                                        <Space direction="vertical" Gap>
                                            <input
                                                value={newItem.name}
                                                onChange={(e) => setNewItem({...newItem, name: e.target.value})}
                                                placeholder="Item Name"
                                                required
                                            />
                                            <input
                                                value={newItem.description}
                                                onChange={(e) => setNewItem({...newItem, description: e.target.value})}
                                                placeholder="Item Description"
                                                required
                                            />
                                            <Button type="primary" onClick={handleAdd}>Add Item</Button>
                                        </Space>
                                    </form>
                                    <Divider/>
                                    <input
                                        value={filter}
                                        onChange={(e) => setFilter(e.target.value)}
                                        placeholder="Filter items"
                                    />
                                    <Divider/>
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        items.map((item:Item) => (
                                            <Card key={item._id}>
                                                <Space direction="horizontal" Gap>
                                                    <span>{item.name}</span>
                                                    <span>{item.description}</span>
                                                    <Button onClick={() => handleDelete(item.uuid)}>Delete</Button>
                                                    <Button
                                                        onClick={() => handleUpdate(item.uuid, {name: 'Updated Name'})}>Update</Button>
                                                </Space>
                                            </Card>
                                        ))
                                    )}
                                    <Space direction="horizontal" Gap>
                                        <Button
                                            onClick={() => setPageIndex(Math.max(0, pageIndex - 1))}>Previous</Button>
                                        <Button onClick={() => setPageIndex(pageIndex + 1)}>Next</Button>
                                    </Space>
                                </Space>
                            </Col>
                        </Row>
                    </div>
                </Content>
            </Page>
            <Footer/>
        </Layout>
    );
};
