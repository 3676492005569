import React, { useState } from 'react';
import {
    Button,
    Card,
    Col,
    Content,
    DropDown,
    Input,
    Layout,
    Link,
    Page,
    Paragraph,
    Row,
    Space,
    Title,
} from '../layout/Content';
import { Drawer, Modal } from '../layout/Defaults';

export const PhotoEditingPage: React.FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [currentTool, setCurrentTool] = useState<string | null>(null);
    const [currentAction, setCurrentAction] = useState<string | null>(null);
    const [fileUploadProgress, setFileUploadProgress] = useState<number>(0);
    const [uploadedFiles, setUploadedFiles] = useState<number>(0);

    const openModal = () => setIsModalVisible(true);
    const closeModal = () => setIsModalVisible(false);

    const openDrawer = () => setIsDrawerVisible(true);
    const closeDrawer = () => setIsDrawerVisible(false);

    const handleToolSelect = (tool: string) => setCurrentTool(tool);
    const handleActionSelect = (action: string) => setCurrentAction(action);

    const mockUploadFile = () => {
        setFileUploadProgress(0);
        const interval = setInterval(() => {
            setFileUploadProgress(prev => {
                if (prev >= 100) {
                    clearInterval(interval);
                    setUploadedFiles(prev => prev + 1);
                    return 100;
                }
                return prev + 10;
            });
        }, 200);
    };

    return (
        <Page>
            <Layout>
                <Row>
                    <Col xs={24} sm={6}>
                        <Card>
                            <Title>Tools</Title>
                            <Space Gap>
                                <Button onClick={() => handleToolSelect('Brush')}>Brush</Button>
                                <Button onClick={() => handleToolSelect('Eraser')}>Eraser</Button>
                                <Button onClick={() => handleToolSelect('AI Enhance')}>AI Enhance</Button>
                                <Button onClick={() => handleToolSelect('Face Tools')}>Face Tools</Button>
                                <Button onClick={() => handleToolSelect('Tagging Tools')}>Tagging Tools</Button>
                                <Button onClick={() => handleToolSelect('Metadata')}>Metadata</Button>
                                <Button onClick={() => handleToolSelect('Adjust Brightness')}>Adjust Brightness</Button>
                                <Button onClick={() => handleToolSelect('Adjust Contrast')}>Adjust Contrast</Button>
                                <Button onClick={() => handleToolSelect('Sharpen')}>Sharpen</Button>
                                <Button onClick={() => handleToolSelect('Blur')}>Blur</Button>
                                <Button onClick={() => handleToolSelect('Saturation')}>Saturation</Button>
                            </Space>
                        </Card>
                        <Card>
                            <Title>Actions</Title>
                            <Space Gap>
                                <Button onClick={() => handleActionSelect('Crop')}>Crop</Button>
                                <Button onClick={() => handleActionSelect('Resize')}>Resize</Button>
                                <Button onClick={() => handleActionSelect('Rotate')}>Rotate</Button>
                                <Button onClick={() => handleActionSelect('Filters')}>Filters</Button>
                                <Button onClick={openModal}>Modify Image</Button>
                            </Space>
                        </Card>
                        <Card>
                            <Title>Settings</Title>
                            <Space  Gap>
                                <Button onClick={openDrawer}>Open Settings</Button>
                            </Space>
                        </Card>
                    </Col>
                    <Col xs={24} sm={18}>
                        <Content>
                            <Title>Photo Editor</Title>
                            <Paragraph>Current Tool: {currentTool || 'None'}</Paragraph>
                            <Paragraph>Current Action: {currentAction || 'None'}</Paragraph>
                            <Button onClick={mockUploadFile}>Upload File</Button>
                            <Paragraph>Upload Progress: {fileUploadProgress}%</Paragraph>
                            <Paragraph>Total Files Uploaded: {uploadedFiles}</Paragraph>
                            <DropDown>
                                <Button>More Options</Button>
                                <div>
                                    <Button onClick={openModal}>Open Modal</Button>
                                    <Button onClick={openDrawer}>Open Drawer</Button>
                                </div>
                            </DropDown>
                        </Content>
                    </Col>
                </Row>
            </Layout>

            <Modal
                visible={isModalVisible}
                title="Modify Image"
                content="This is a mock modal for image modification."
                onClose={closeModal}
            />
            <Drawer
                visible={isDrawerVisible}
                title="Settings"
                content="This is a mock drawer for settings."
                onClose={closeDrawer}
            />
        </Page>
    );
};

