import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Content, Input, Layout, Page, Row, Small, Space, Title } from '../layout/Content';
import { ProvidedAttribute, UserAttribute, useUserProfileContext } from "../../lib/UserContext";
import { Header } from "../part/Header";
import { Footer } from "../part/Footer";
import { Loading, Result, Tooltip } from "../layout/Defaults";
import useFormMethodValidation from "../../lib/FormMethodValidation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGenderless,
    faMars, faMarsAndVenus,
    faNeuter, faPersonHalfDress,
    faTransgenderAlt,
    faTriangleExclamation,
    faVenus
} from "@fortawesome/free-solid-svg-icons";
import {Select} from "../part/Select";

const UserSettings: React.FC = () => {

    const defaultUserDetails: any = {
        gender: { value: undefined, public: undefined },
        birthday: { value: undefined, public: undefined }
    };


    const [attributes, setAttributes] = useState<UserAttribute>({
        displayName: '',
        username: '',
        userDetails: defaultUserDetails,
    });

    const [provided, setProvided] = useState<ProvidedAttribute>({
        firstName: '',
        lastName: '',
        email: '',
        profilePictureUrl: '',
        region: '',
    });

    const { setError, getError, getHandlers } = useFormMethodValidation(['attributes.displayName', 'attributes.username', 'attributes.userDetails.gender.value', 'attributes.userDetails.birthday.value']);

    const { queryUserProfile, getUserProfile, setUserProfile, hasQueriedUserProfile } = useUserProfileContext();
    const navigate = useNavigate();

    useEffect(() => {
        const profile = getUserProfile();
        if (profile) {
            setAttributes({
                ...profile.attributes,
                userDetails: {
                    ...defaultUserDetails,
                    ...profile.attributes.userDetails
                }
            });
            setProvided(profile.provided);
        }
    }, [getUserProfile]);

    const handleInputChange = (e: any, section: 'attributes' | 'userDetails', detailKey?: keyof any) => {
        const { name, value, checked, type } = e.target;
        if (section === 'attributes') {
            setAttributes({ ...attributes, [name]: value });
        } else if (section === 'userDetails' && detailKey) {
            // @ts-ignore
            setAttributes({
                ...attributes,
                userDetails: {
                    ...attributes.userDetails,
                    [detailKey]: {/* @ts-ignore */
                        ...attributes.userDetails[detailKey],
                        ...(type === 'checkbox' ? { public: checked } : { value })
                    }
                }
            });
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

        const updatedData = {
            attributes,
            provided,
        };

        try {
            const response = await fetch(`https://api.peek.blog/user/${getUserProfile()?.uuid}`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(updatedData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.errors) {
                    errorData.errors.forEach((error: { path: string; msg: string }) => {
                        setError(error.path, error.msg);
                    });
                }
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setUserProfile(result.results[0]);
        } catch (error) {
            console.error('Error updating user information:', error);
        }
    };

    return (
        <Layout className="user-settings-page">
            <Header />
            <Page Grow>
                {getUserProfile() ? (
                    <Content>
                        <div className="limits wide pad">
                            <Row Gap Fill>
                                <Col xs={24}>
                                    <Space direction="vertical" Gap>
                                        <Title Large>User Settings</Title>

                                            <Space direction="vertical" GapSm>
                                                <label htmlFor="displayName">Display Name</label>
                                                <Space direction={"vertical"}>
                                                    <Input
                                                        type="text"
                                                        id="displayName"
                                                        name="displayName"
                                                        value={attributes.displayName}
                                                        notification={getError('attributes.displayName') && <Tooltip message={getError('attributes.displayName')}><FontAwesomeIcon fixedWidth icon={faTriangleExclamation} /></Tooltip>}
                                                        onChange={(e: any) => {
                                                            getHandlers()['attributes.displayName'].onChange(e);
                                                            handleInputChange(e, 'attributes');
                                                        }}
                                                        className={getError('attributes.displayName') ? "Error" : ""}
                                                        placeholder={"Display Name"}
                                                    />
                                                    {getError('attributes.displayName') && <Small className={"Text Dark Error"}><FontAwesomeIcon fixedWidth icon={faTriangleExclamation} /> {getError('attributes.displayName')}</Small>}
                                                </Space>

                                                <label htmlFor="username">Username</label>
                                                <Space direction={"vertical"}>
                                                    <Input
                                                        type="text"
                                                        id="username"
                                                        name="username"
                                                        value={attributes.username}
                                                        notification={getError('attributes.username') && <Tooltip message={getError('attributes.username')}><FontAwesomeIcon fixedWidth icon={faTriangleExclamation} /></Tooltip>}
                                                        onChange={(e: any) => {
                                                            getHandlers()['attributes.username'].onChange(e);
                                                            handleInputChange(e, 'attributes');
                                                        }}
                                                        className={getError('attributes.username') ? "Error" : ""}
                                                        placeholder={"Username"}
                                                    />
                                                    {getError('attributes.username') && <Small className={"Text Dark Error"}><FontAwesomeIcon fixedWidth icon={faTriangleExclamation} /> {getError('attributes.username')}</Small>}
                                                </Space>

                                                <Space justify={"space-between"} GapSm><label htmlFor="gender">Gender</label>

                                                    <Space GapSm> <input
                                                        type="checkbox"
                                                        id="gender"
                                                        name="gender"
                                                        checked={attributes.userDetails?.gender?.public || false}
                                                        onChange={(e) => handleInputChange(e, 'userDetails','gender')}
                                                    />
                                                    <label>Public</label>
                                                    </Space></Space>
                                                <Space direction={"vertical"}>

                                                    <div><Select
                                                        defaultValue={attributes.userDetails?.gender?.value}
                                                        placeholder={<Space align="center" GapSm><FontAwesomeIcon icon={faPersonHalfDress} fixedWidth={true} /> Select a Gender</Space>}
                                                        options={[
                                                            {
                                                                value: 'mars',
                                                                element: <Space align="center" GapSm><FontAwesomeIcon icon={faMars} fixedWidth={true} /> Mars</Space>
                                                            },
                                                            {
                                                                value: 'venus',
                                                                element: <Space align="center" GapSm><FontAwesomeIcon icon={faVenus} fixedWidth={true} /> Venus</Space>
                                                            },
                                                            {
                                                                value: 'non-binary',
                                                                element: <Space align="center" GapSm><FontAwesomeIcon icon={faNeuter} fixedWidth={true} /> Non-Binary</Space>
                                                            },
                                                            {
                                                                value: 'mars-and-venus',
                                                                element: <Space align="center" GapSm><FontAwesomeIcon icon={faMarsAndVenus} fixedWidth={true} /> Mars & Venus</Space>
                                                            },
                                                            {
                                                                value: 'genderless',
                                                                element: <Space align="center" GapSm><FontAwesomeIcon icon={faGenderless} fixedWidth={true} /> Genderless</Space>
                                                            }
                                                        ]}
                                                        onSelected={(value: string) => {
                                                            console.log("Selected Gender:", value);
                                                            getHandlers()['attributes.userDetails.gender.value'].onChange();
                                                            handleInputChange({name:"gender",target:{value}}, 'userDetails', 'gender')
                                                            //  onChange(value);
                                                        }}
                                                    /></div>

                                                    {getError('attributes.userDetails.gender.value') && <Small className={"Text Dark Error"}><FontAwesomeIcon fixedWidth icon={faTriangleExclamation} /> {getError('attributes.userDetails.gender.value')}</Small>}
                                                </Space>

                                                <Space justify={"space-between"}>

                                                    <label htmlFor="birthday">Birthday</label>

                                                    <Space GapSm><input
                                                        type="checkbox"
                                                        name="birthday"
                                                        checked={attributes.userDetails?.birthday?.public || false}
                                                        onChange={(e) => handleInputChange(e, 'userDetails','birthday')}
                                                    />
                                                    <label>
                                                    Public
                                                    </label></Space>
                                                </Space>
                                                <Space direction={"vertical"}>
                                                    <Input
                                                        type="text"
                                                        id="birthday"
                                                        name="birthday"
                                                        value={attributes.userDetails?.birthday?.value || ''}
                                                        onChange={(e) => handleInputChange(e, 'userDetails', 'birthday')}
                                                        placeholder="MM-DD-YYYY"
                                                    />

                                                    {getError('attributes.userDetails.birthday.value') && <Small className={"Text Dark Error"}><FontAwesomeIcon fixedWidth icon={faTriangleExclamation} /> {getError('attributes.userDetails.birthday.value')}</Small>}
                                                </Space>

                                                <Button type="primary" onClick={handleSubmit}>
                                                    Update Information
                                                </Button>
                                            </Space>

                                    </Space>
                                </Col>
                            </Row>
                        </div>
                    </Content>
                ) : (hasQueriedUserProfile() ? (
                    <Content><Result
                        title={<strong>Settings Unavailable</strong>}
                        subTitle="You must be logged in to change Settings."
                        status={"error"} /></Content>
                ) : <Content><Loading /></Content>)}
            </Page>
            <Footer />
        </Layout>
    );
};

export default UserSettings;
