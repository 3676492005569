import React, {useEffect, useState} from 'react';
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import {Col, Content, Layout, Page, Row, Space, Title} from "../layout/Content";
import {Breadcrumb, Loading, Result, Tabs} from "../layout/Defaults";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse, faUsersViewfinder} from "@fortawesome/free-solid-svg-icons";
import {faUser} from "@fortawesome/free-regular-svg-icons";
import {timeAgo} from "../../lib/Time";
import {UserProfile} from "../../lib/UserContext";
import {Header} from "../part/Header";
import {Footer} from "../part/Footer";

interface Community {
    uuid: string;
    name:string;
    description:string;
    owner:any;
    createdAt: string;
}

const CommunityViewer: React.FC = () => {
    const {uuid} = useParams<{ uuid: string }>();

    const [community, setCommunity] = useState<Community | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();


    useEffect(() => {
        setError(null);
        setLoading(true);
        const fetchUserProfile = async () => {
            try {
                const response = await fetch(`//${process.env.REACT_APP_DOMAIN_API}/community/${uuid}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data: any = await response.json();
                console.log("COMMUNITY DATA", data);
                setCommunity(data.results[0]);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch user profile.');
                setLoading(false);
                console.error(err);
            }
        };
        fetchUserProfile();
    }, [uuid]);

    if (loading) return <Loading/>;
    if (error) return <Content><Result
        title={<strong>Error Occured</strong>}
        subTitle={error}
        status={"error"}/></Content>;

    if (!community) return<Result
        title={<strong>User Profile Unavailable</strong>}
        subTitle={"User profile not found"}
        status={"error"}/>;

    return (

        <Layout className="landing-page">
            <Header/>

            <Page Grow>
                <Content style={{height: "100%"}}>
                    <div className={"limits pad"}>

                        <Row>


                            <Col xs={24} md={12}>
                                <Content>
                                    <Space direction={"vertical"} style={{display: 'flex', width: '100%'}}>
                                        <Breadcrumb items={[<FontAwesomeIcon fixedWidth icon={faHouse}/>,"Community", <span><FontAwesomeIcon fixedWidth icon={faUsersViewfinder} /> {community.name}</span>]}/>
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: 20}}>
                                            <div style={{marginLeft: 20}}>
                                                <Title Large>{community.name}</Title>
                                                <p>Created: {timeAgo(community.createdAt)}</p>
                                            </div>
                                        </div>

                                    </Space>
                                </Content>
                            </Col>

                        </Row>
                    </div>
                </Content>
            </Page>
            <Footer/>
        </Layout>
    );

};

export default CommunityViewer;