import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faVideo } from '@fortawesome/free-solid-svg-icons';
import {Card, Col, Space} from "../layout/Content";

export interface MediaI {
    _id: string;
    attributes: Attributes;
    extension: string;
    original: Original;
    owner: Owner;
    type: 'image' | 'video';
    uuid: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
    transformed?: Transformed;
    likes: Likes;
    comments: Comments;
}

interface Attributes {
    public: boolean;
    _id: string;
}

interface Original {
    _id: string;
    filepath: string;
    filename: string;
    filesize: number;
    extension: string;
    mimetype: string;
}

interface Owner {
    _id: string;
    uuid: string;
    googleId: string;
    attributes: OwnerAttributes;
    provided: string;
    createdAt: string;
    __v: number;
}

interface OwnerAttributes {
    _id: string;
    displayName: string;
    username: string;
    userDetails: UserDetails;
}

interface UserDetails {
    _id: string;
    gender: Gender;
    birthday: Birthday;
}

interface Gender {
    _id: string;
    public: string;
    value: string;
}

interface Birthday {
    _id: string;
    public: string;
    value: string;
}

interface Transformed {
    _id: string;
    filepaths: FilePaths;
}

interface FilePaths {
    _id: string;
    thumbnail: string;
    blurred: string;
}

interface Likes {
    total_likes: number;
    current_user_likes: boolean;
}

interface Comments {
    total_comments: number;
}


interface MediaItemProps {
    item: MediaI;
}

const MediaItemComponent: React.FC<MediaItemProps> = ({ item }) => {
    const imageUrl = `//asset.peek.blog/media/${item.uuid}.jpg`;
    const thumbnailUrl = `//asset.peek.blog/media/${item.uuid}.jpg`;
    const videoUrl = `//asset.peek.blog/media/${item.uuid}.mp4`;

    return (
        <Col xs={24} md={12} lg={8}>
            <Card style={{ padding: '10px', width: '100%' }}>
                <Space direction="vertical"  style={{ width: '100%' }}>
                    {item.type === 'image' && (
                        <>
                            <FontAwesomeIcon icon={faImage} />
                            <img src={thumbnailUrl} alt={item.original.filename} style={{ width: '100%' }} />
                        </>
                    )}
                    {item.type === 'video' && (
                        <>
                            <FontAwesomeIcon icon={faVideo} />
                            <video controls style={{ width: '100%' }} poster={thumbnailUrl}>
                                <source src={videoUrl} type={"video/mp4"} />
                                Your browser does not support the video tag.
                            </video>
                        </>
                    )}
                    <div>
                        <strong>Filename:</strong> {item.original.filename}
                    </div>
                    <div>
                        <strong>Filesize:</strong> {(item.original.filesize / (1024 * 1024)).toFixed(2)} MB
                    </div>
                    <div>
                        <strong>Owner:</strong> {item.owner.attributes.displayName} ({item.owner.attributes.username})
                    </div>
                </Space>
            </Card>
        </Col>
    );
};

export default MediaItemComponent;
