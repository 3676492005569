import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Col,
    Content,
    Layout,
    Page,
    Row,
    Space,
    Title,
    Divider,
    Input,
    Paragraph,
} from '../layout/Content';
import { Drawer, Modal } from '../layout/Defaults';

interface PropertySchema {
    type: string;
    required: boolean;
}

interface CollectionSchema {
    properties: Record<string, PropertySchema>;
    requiredFields: string[];
    exampleCurlRequests: Record<string, string>;
}

interface QueryResult<T> {
    results: T[];
    query: {
        total_items: number;
        page: {
            index: number;
            size: number;
        };
        timing: number;
    };
}

const API_URL = '//api.peek.blog';

const CollectionManager: React.FC = () => {
    const [selectedCollection, setSelectedCollection] = useState<string>('user');
    const [schema, setSchema] = useState<CollectionSchema | null>(null);
    const [items, setItems] = useState<any[]>([]);
    const [filter, setFilter] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [pageSize] = useState<number>(5); // Fixed page size
    const [editItem, setEditItem] = useState<any | null>(null);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const fetchSchema = async (collection: string): Promise<void> => {
        try {
            const response = await fetch(`${API_URL}/${collection}/schema`);
            const result: CollectionSchema = await response.json();
            setSchema(result);
        } catch (error) {
            console.error('Error fetching schema:', error);
        }
    };

    const fetchItems = async (collection: string, filter: string, pageIndex: number, pageSize: number): Promise<void> => {
        setLoading(true);
        try {
            const response = await fetch(`${API_URL}/${collection}?filter=${filter}&page=${pageIndex}&size=${pageSize}`);
            const result: QueryResult<any> = await response.json();
            setItems(result.results);
        } catch (error) {
            console.error('Error fetching items:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (collection: string, id: string): Promise<void> => {
        try {
            const response = await fetch(`${API_URL}/${collection}/${id}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                fetchItems(collection, filter, pageIndex, pageSize);
            }
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    const handleUpdate = async (collection: string, item: any): Promise<void> => {
        try {
            const response = await fetch(`${API_URL}/${collection}/${item._id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include', // This tells the browser to include cookies with the request
                body: JSON.stringify(item),
            });
            if (response.ok) {
                fetchItems(collection, filter, pageIndex, pageSize);
            }
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };

    useEffect(() => {
        fetchSchema(selectedCollection);
    }, [selectedCollection]);

    useEffect(() => {
        fetchItems(selectedCollection, filter, pageIndex, pageSize);
    }, [selectedCollection, filter, pageIndex]);

    const openModal = (item: any) => {
        setEditItem(item);
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setEditItem(null);
    };

    const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (editItem) {
            setEditItem({ ...editItem, [e.target.name]: e.target.value });
        }
    };

    const handleEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (editItem) {
            await handleUpdate(selectedCollection, editItem);
            closeModal();
        }
    };

    const renderItemProperty = (item: any, key: string) => {
        if (typeof item[key] === 'object' && item[key] !== null) {
            return (
                <div key={key}>
                    <strong>{key}:</strong>
                    <div style={{ paddingLeft: '10px' }}>
                        {Object.entries(item[key]).map(([subKey, subValue]) => (
                            <div key={subKey}>
                                <strong>{subKey}:</strong> {String(subValue)}
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
        return (
            <div key={key}>
                <strong>{key}:</strong> {String(item[key])}
            </div>
        );
    };

    return (
        <Layout className="collection-manager">
            <Page Grow>
                <Content style={{ height: '100%' }}>
                    <div className="limits pad">
                        <Row>
                            <Col xs={24}>
                                <Space direction="vertical" Gap>
                                    <Title Large>{selectedCollection.charAt(0).toUpperCase() + selectedCollection.slice(1)} Manager</Title>
                                    <Divider />
                                    <Space direction="horizontal" Gap>
                                        <Button onClick={() => setSelectedCollection('user')}>User</Button>
                                        <Button onClick={() => setSelectedCollection('community')}>Community</Button>
                                        <Button onClick={() => setSelectedCollection('post')}>Post</Button>
                                        <Button onClick={() => setSelectedCollection('like')}>Like</Button>
                                        <Button onClick={() => setSelectedCollection('follow')}>Follow</Button>
                                        <Button onClick={() => setSelectedCollection('data')}>Data</Button>
                                        <Button onClick={() => setSelectedCollection('media')}>Media</Button>
                                    </Space>
                                    <Divider />
                                    <Input
                                        type="text"
                                        value={filter}
                                        onChange={(e) => setFilter(e.target.value)}
                                        placeholder={`Filter ${selectedCollection}s`}
                                    />
                                    <Divider />
                                    <h2>Selected `{selectedCollection}` Schema</h2>
                                    {schema&&<Space>
                                        <pre>{JSON.stringify(schema.properties,null,"\t")}</pre>
                                    </Space>}
                                    <Divider />
                                    {loading ? (
                                        <Paragraph>Loading...</Paragraph>
                                    ) : (
                                        items.map((item) => (
                                            <Card key={item._id}>
                                                <Space direction="horizontal" Gap>
                                                    {Object.keys(schema?.properties || {}).map((key) => (
                                                        renderItemProperty(item, key)
                                                    ))}
                                                    <Button onClick={() => handleDelete(selectedCollection, item._id)}>Delete</Button>
                                                    <Button onClick={() => openModal(item)}>Edit</Button>
                                                </Space>
                                            </Card>
                                        ))
                                    )}
                                    <Space direction="horizontal" Gap>
                                        <Button onClick={() => setPageIndex(Math.max(0, pageIndex - 1))}>Previous</Button>
                                        <Button onClick={() => setPageIndex(pageIndex + 1)}>Next</Button>
                                    </Space>
                                </Space>
                            </Col>
                        </Row>
                    </div>
                </Content>
            </Page>

            {editItem && (
                <Modal
                    visible={isModalVisible}
                    title={`Edit ${selectedCollection.charAt(0).toUpperCase() + selectedCollection.slice(1)}`}
                    content={
                        <form onSubmit={handleEditSubmit}>
                            <Space direction="vertical" Gap>
                                {Object.keys(schema?.properties || {}).map((key) => (
                                    <Input
                                        key={key}
                                        type="text"
                                        //name={key}
                                        value={editItem[key] || ''}
                                        onChange={handleEditChange}
                                        placeholder={key}
                                    />
                                ))}
                                <Button type="primary">Save</Button>
                                <Button type="default" onClick={closeModal}>Cancel</Button>
                            </Space>
                        </form>
                    }
                    onClose={closeModal}
                />
            )}

        </Layout>
    );
};

export default CollectionManager;
