import React, {useEffect, useState} from 'react';
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import {Col, Content, Layout, Page, Row, Small, Space, Title} from "../layout/Content";
import {Breadcrumb, Result, Skeleton, Tabs} from "../layout/Defaults";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faUser} from "@fortawesome/free-regular-svg-icons";
import {timeAgo} from "../../lib/Time";
import {Header} from "../part/Header";
import {Footer} from "../part/Footer";

interface UserProfile {
    uuid: string;
    attributes: {
        locale: string;
        displayName: string;
        email: string;
        username: string;
    };
    createdAt: string;
}

const UserProfileViewer: React.FC = () => {
    const {uuid} = useParams<{ uuid: string }>();

    const [user, setUser] = useState<UserProfile | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();


    useEffect(() => {
        setError(null);
        setLoading(true);
        const fetchUserProfile = async () => {
            try {
                const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/user/${uuid}`, {
                    method: 'GET',
                    credentials: 'include',
                    mode: 'cors', // This needs to be 'cors' to follow CORS rules
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data: any = await response.json();
                console.log('USER DATA', data);
                setUser(data.results[0]);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch user profile.');
                setLoading(false);
                console.error(err);
            }
        };
        fetchUserProfile();
    }, [uuid]);

    //if (loading) return <div>Loading</div>;
    if (error) return <Content><Result
        title={<strong>Error Occurred</strong>}
        subTitle={error}
        status={"error"}/></Content>;

    if (!user && !loading) return <Result
        title={<strong>User Profile Unavailable</strong>}
        subTitle={"User profile not found"}
        status={"error"}/>;

    return (
        <Layout className="landing-page">
            <Header/>

            <Page Grow>
                <Content style={{height: "100%"}}>
                    <div className={"limits pad"}>

                        <Row Fill>
                            <Col xs={24}>
                                <Content>
                                    <Space direction={"vertical"} style={{display: 'flex', width: '100%'}} Gap>
                                        <Breadcrumb items={[<FontAwesomeIcon fixedWidth icon={faHouse}/>, "User",
                                            <span><FontAwesomeIcon fixedWidth
                                                                   icon={faUser}/> <span>{user ? user.attributes.displayName :
                                                <Skeleton rows={1} width={("user".length)}/>}</span></span>]}/>
                                        <Title Large><span>{user ? (<span>{user.attributes.displayName} {<Small Muted>@{(user.attributes.username)}</Small>}</span>) :
                                            <Skeleton rows={1} width={("displayName".length)}/>}</span></Title>
                                        <p><span>User since: {user ? timeAgo(user.createdAt) :
                                            <Skeleton rows={1} width={("5 days ago".length)}/>}</span></p>
                                        <p><span>Username: {user ? user.attributes.username :
                                            <Skeleton rows={1} width={("Skeleton".length)}/>}</span></p>
                                    </Space>
                                </Content>
                            </Col>

                        </Row>
                    </div>
                </Content>
            </Page>
            <Footer/>
        </Layout>
    );

};

export default UserProfileViewer;