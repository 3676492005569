export const getAvatarURLForUser=(user:any):string=>{
    return `https://api.dicebear.com/8.x/adventurer-neutral/svg?backgroundColor=b6e3f4,c0aede,d1d4f9&seed=${user.attributes.username}`;
}


export const IconLetterB = '𝐁';
export const IconLetterI = '𝑰';

export const IconComplex = '҈';
export const IconSymbols1 = '֍';
export const IconSymbols2 = '֎';

export const IconMathSum = '∑';
export const IconMathProduct = '∏';
export const IconMathAsterisk = '∗';
export const IconMathCircle = '∘';
export const IconMathDot = '∙';
export const IconMathSquareRoot = '√';
export const IconMathCubeRoot = '∛';
export const IconMathInfinity = '∞';
export const IconMathSineWave = '∿';
export const IconMathPlus = '➕';
export const IconMathMinus = '➖';
export const IconMathDivide = '➗';

export const IconDagger = '†';
export const IconDoubleDagger = '‡';

export const IconBullet = '•';
export const IconTriangularBullet = '‣';

export const IconDot = '․';
export const IconTwoDots = '‥';
export const IconThreeDots = '…';
export const IconFourDots = '⁖';
export const IconFiveDots = '⁘';
export const IconSixDots = '⁙';
export const IconSevenDots = '⁚';
export const IconEightDots = '⁛';
export const IconNineDots = '⁝';
export const IconTenDots = '⁞';
export const IconVerticalEllipsis = '⋮';
export const IconMidlineEllipsis = '⋯';
export const IconUpperLeftDiagonalEllipsis = '⋰';
export const IconUpperRightDiagonalEllipsis = '⋱';

export const IconCaret = '‸';
export const IconLeftSingleQuotationMark = '‹';
export const IconRightSingleQuotationMark = '›';

export const IconMark = '※';

export const IconTie = '‿';
export const IconDoubleTie = '⁀';
export const IconTripleTie = '⁁';
export const IconQuadrupleTie = '⁔';
export const IconSplitTie = '␥';
export const IconLeftHalfTie = '⚞';
export const IconRightHalfTie = '⚟';

export const IconAsterisk = '⁂';
export const IconTwoAsterisks = '⁎';
export const IconThreeAsterisks = '⁑';
export const IconFourAsterisks = '⁕';
export const IconFiveAsterisks = '✱';
export const IconSixAsterisks = '✲';

export const IconClose = '⁐';

export const IconTinyAsterisk1 = '℀';
export const IconTinyAsterisk2 = '℁';
export const IconTinyAsterisk3 = '℅';
export const IconTinyAsterisk4 = '℆';

export const IconLegalCopyright = '℗';
export const IconLegalTrademark = '℠';
export const IconLegalServiceMark = '℡';
export const IconLegalRegisteredTrademark = '™';
export const IconLegalTelephoneMark = '℻';
export const IconLegalCapital = '⅍';

export const IconArrowLeft = '←';
export const IconArrowUp = '↑';
export const IconArrowRight = '→';
export const IconArrowDown = '↓';
export const IconArrowLeftRight = '↔';
export const IconArrowUpDown = '↕';
export const IconArrowUpperLeft = '↖';
export const IconArrowUpperRight = '↗';
export const IconArrowLowerRight = '↘';
export const IconArrowLowerLeft = '↙';
export const IconArrowBack = '↚';
export const IconArrowForward = '↛';
export const IconArrowLeftRightArrow = '↮';
export const IconArrowWaveUpDown = '↯';
export const IconArrowRightUp = '⇅';
export const IconArrowLeftRightUp = '⇄';
export const IconArrowLeftDown = '➔';
export const IconArrowUpward = '➧';

export const IconArrowRound = '↺';
export const IconArrowAntiRound = '↻';
export const IconArrowRightUpward = '⤴';
export const IconArrowRightDownward = '⤵';
export const IconArrowTurnLeft = '⤶';
export const IconArrowTurnRight = '⤷';
export const IconArrowTurnUp = '⤸';
export const IconArrowTurnDown = '⤹';
export const IconArrowTurnUpward = '⤺';
export const IconArrowTurnDownward = '⤻';
export const IconArrowLoopUp = '⮐';
export const IconArrowLoopDown = '⮑';

export const IconCirclePlus = '⊕';
export const IconCircleMinus = '⊖';
export const IconCircleTimes = '⊗';
export const IconCircleDivide = '⊘';
export const IconCircleDot = '⊙';
export const IconCircleDoubleDot = '⊚';
export const IconCircleTripleDot = '⊛';
export const IconCircleQuadrupleDot = '⊜';
export const IconCircleTilde = '⦶';
export const IconCircleDoubleTilde = '⦷';
export const IconCircleDashed = '⊝';
export const IconCircleVerticalLine = '⎉';
export const IconCircleHorizontalLine = '⎊';
export const IconCircleDashDot = '⎋';
export const IconCircleDotDot = '⏻';
export const IconCircleDotDotDot = '⏼';
export const IconCircleEmpty = '◌';
export const IconCircleFull = '◍';
export const IconCircleBigDot = '◎';
export const IconCircleDotMedium = '●';
export const IconCircleHalf = '◐';
export const IconCircleHalfLeft = '◑';
export const IconCircleHalfRight = '◒';
export const IconCircleHalfBottom = '◓';
export const IconCircleHalfTop = '◔';
export const IconCircleThreeQuarterLeft = '◖';
export const IconCircleThreeQuarterRight = '◗';
export const IconCircleQuarterTopLeft = '◠';
export const IconCircleQuarterTopRight = '◡';
export const IconCircleQuarterBottomLeft = '☼';
export const IconCircleQuarterBottomRight = '☽';
export const IconCircleQuarterTop = '✪';
export const IconCircleQuarterBottom = '⦵';

export const IconSquarePlus = '⊞';
export const IconSquareMinus = '⊟';
export const IconSquareTimes = '⊠';
export const IconSquareDivide = '⊡';
export const IconSquareEmpty = '□';
export const IconSquareHalf = '▢';
export const IconSquareQuarter = '▣';
export const IconSquareThreeQuarter = '▤';
export const IconSquareFull = '▥';
export const IconSquareDashed = '▦';
export const IconSquareSolid = '▧';
export const IconSquareDot = '▨';
export const IconSquareDotDot = '▩';
export const IconSquareQuad = '◧';
export const IconSquareTri = '◨';
export const IconSquareHex = '◩';
export const IconSquareOct = '◪';
export const IconSquarePent = '◫';

export const IconSquareTick = '☑';
export const IconSquareCross = '☒';
export const IconSquareGrid = '⚿';
export const IconSquareExpand = '⛶';

export const IconRectangleEmpty = '▭';
export const IconRectangleHalf = '▯';
export const IconRectangleFull = '▱';

export const IconShapeHouse = '⌂';
export const IconShapeKey = '⌑';
export const IconShapeArc = '⌒';
export const IconShapeHalfCircle = '⌔';
export const IconShapeCrosshair = '⌖';
export const IconShapeStar = '★';
export const IconShapeStarEmpty = '☆';
export const IconShapeHeart = '♡';
export const IconShapeHeartFull = '♥';
export const IconShapeFlag = '⚐';
export const IconShapeFlagOutline = '⚑';
export const IconShapeAnchor = '⚓';
export const IconShapeFleurDeLis = '⚜';
export const IconShapeClover = '✿';
export const IconShapeSnowflake = '❄';
export const IconShapeSun = '☼';
export const IconShapeMoon = '☽';

export const IconKeyAlt = '⌥';
export const IconKeyCommand = '⌘';
export const IconKeyForwardDelete = '⌦';
export const IconKeyBackwardDelete = '⌫';
export const IconKeyOption = '⌥';
export const IconKeyEnter = '⏎';

export const IconItemPointer = '⌕';
export const IconItemFlag = '⚑';
export const IconItemCircle = '⏻';
export const IconItemHexagon = '⏼';
export const IconItemDiamond = '◈';
export const IconItemSquare = '◫';

export const IconTechnologyKeyboard = '⌨';
export const IconTechnologyGear = '⚙';

export const IconActionUndo = '⎌';
export const IconActionArrowUp = '⎗';
export const IconActionArrowDown = '⎘';
export const IconActionArrowRight = '⎙';
export const IconActionEject = '⏏';
export const IconActionWarning = '⚠';

export const IconOCR = '⑆';

export const IconGenderMale = '♂';
export const IconGenderFemale = '♀';
export const IconGenderOther = '⚲';
export const IconGenderMaleFemale = '⚥';
export const IconGenderFemaleFemale = '⚢';
export const IconGenderMaleMale = '⚣';
export const IconGenderMaleOther = '⚤';
export const IconGenderNone = '⚬';

export const IconBlockFull = '█';
export const IconBlockHalf = '▇';
export const IconBlockQuarter = '▃';
export const IconBlockEighth = '▂';

export const IconCheck = '✓';
export const IconCheckAlt = '✔';
export const IconCross = '✗';
export const IconCrossAlt = '✘';

export const IconQuotationLeft = '❛';
export const IconQuotationRight = '❜';
export const IconDoubleQuotationLeft = '❝';
export const IconDoubleQuotationRight = '❞';

export const IconParenthesisLeft = '❨';
export const IconParenthesisRight = '❩';

export const IconArrowLeftSmall = '❮';
export const IconArrowRightSmall = '❯';

export const IconGreekAlpha = '𐀆';
export const IconGreekBeta = '𐀋';
export const IconGreekGamma = '𐀐';
export const IconGreekDelta = '𐀙';
export const IconGreekEpsilon = '𐀜';
export const IconGreekZeta = '𐀢';
export const IconGreekEta = '𐀤';
export const IconGreekTheta = '𐀪';
export const IconGreekIota = '𐀯';
export const IconGreekKappa = '𐀳';
export const IconGreekLambda = '𐀶';
export const IconGreekMu = '𐀽';
export const IconGreekNu = '𐁄';
export const IconGreekXi = '𐁆';
export const IconGreekOmicron = '𐁇';
export const IconGreekPi = '𐁉';
export const IconGreekRho = '𐁋';
export const IconGreekSigma = '𐁌';
export const IconGreekTau = '𐁍';
export const IconGreekUpsilon = '𐁐';
export const IconGreekPhi = '𐁑';
export const IconGreekChi = '𐂀';
export const IconGreekPsi = '𐂁';
export const IconGreekOmega = '𐁕';

export const IconLinearA = '𐘋';
export const IconLinearB = '𐘐';
export const IconLinearC = '𐘑';
export const IconLinearD = '𐘒';

export const IconHieroglyph = '𐦀';
export const IconBamun = '𖠥';