import React from "react";
import {
    Col,
    Content,
    Layout,
    Page,
    Row,
    Paragraph,
    Space,
    Title,
    Divider,
    Button, Card, Input, Link
} from "../layout/Content";
import { Footer } from "../part/Footer";
import Badge from "../part/Badge";
import { Header } from "../part/Header";
import { Trademark } from "../part/Trademark";

export function Partner() {
    return (
        <Layout className="features-overview-page">
            <Header />
            <Page Grow>
                <Content style={{ height: "100%" }}>
                    <div className={"limits pad"}>
                        <Row Fill>
                            <Col xs={24} className={"flex center"}>
                                <Space direction={"vertical"} Gap>
                                    {/* Header Section */}
                                    <Row align={"center"} Gap>
                                        <Col xs={24}>
                                            <Content>
                                                <Title Large>
                                                    <span className={"heavy"}>the Peek</span>
                                                    <Trademark /> Partner Program
                                                </Title>
                                                <Paragraph>
                                                    Earn money with your content, connect with a vibrant community, and explore powerful tools designed to enhance your creative journey.
                                                </Paragraph>
                                            </Content>
                                        </Col>
                                    </Row>

                                    {/* Why Peek Section */}
                                    <Row Gap>
                                        <Col xs={24} md={12}>

                                            <Content>
                                                <Title>Why the Peek?</Title>
                                                <Paragraph>
                                                    Explore all the classic ways to monetize your content:
                                                </Paragraph>
                                                <ul>
                                                    <li>Subscriptions</li>
                                                    <li>Pay Per Content</li>
                                                    <li>Tips</li>
                                                    <li>Customs</li>
                                                </ul>
                                            </Content>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Content>
                                                <Title>Highest Payouts</Title>
                                                <Paragraph>
                                                    Enjoy the highest payouts in the industry, ranging from 85-110%. Get featured on the biggest websites and grow your brand! More eyes on you and your content.
                                                </Paragraph>
                                            </Content>
                                        </Col>
                                    </Row>

                                    {/* Monetization Methods Section */}
                                    <Row Gap>
                                        <Col xs={24} md={12}>
                                            <Content>
                                                <Title>Monetization Methods</Title>
                                                <Paragraph>
                                                    Access exclusive additional monetization methods and platforms:
                                                </Paragraph>
                                                <ul>
                                                    <li>Pay-Per-View</li>
                                                    <li>Subscriptions</li>
                                                </ul>
                                            </Content>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Content>
                                                <Title>Safety & Privacy</Title>
                                                <Paragraph>
                                                    <ul>
                                                        <li>Automatic Face Detection and Removal</li>
                                                        <li>Sensitive Data and Metadata Removal</li>
                                                        <li>Context, Library, and Gallery Tools</li>
                                                    </ul>
                                                </Paragraph>
                                            </Content>
                                        </Col>
                                    </Row>

                                    {/* Creator Benefits Section */}
                                    <Row Gap>
                                        <Col xs={24}>
                                            <Content>
                                                <Title>Creator Benefits</Title>
                                                <Paragraph>
                                                    With Subscriptions, Pay-Per-View, and custom content options, you can choose how you want to sell your brand with the confidence that it won’t be taken down for being too sexy!
                                                </Paragraph>
                                                <Paragraph>
                                                    Explore our platform and see all opportunities, join a new era of digital services, earn your money!
                                                </Paragraph>
                                            </Content>
                                        </Col>
                                    </Row>

                                    {/* Sign Up Section */}
                                    <Row Gap>
                                        <Col xs={24} md={12}>
                                            <Content>
                                                <Title>Connect, Create, Earn.</Title>
                                                <Paragraph>
                                                    The creator subscription platform for the future.
                                                </Paragraph>
                                            </Content>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Space direction={"vertical"} GapSm>
                                                <Title>Join the fastest growing community of Creators</Title>
                                                <Button type={"disabled"}>Coming Soon</Button>
                                            </Space>
                                        </Col>
                                    </Row>

                                    {/* FAQ Section */}
                                    <Row>
                                        <Col xs={24}>
                                            <Content>
                                                <Space direction={"vertical"} GapSm>
                                                    <Space align={"center"} Gap>
                                                        <Title Large>Frequently Asked Questions</Title>
                                                        <Divider/>
                                                    </Space>
                                                    <Title size="small">What is the Peek?</Title>
                                                    <Paragraph>
                                                        The Peek is an online platform that allows creators to share high-resolution media and earn money from their content. It is designed to support creators in building and monetizing their audience through subscriptions, pay-per-view, and more.
                                                    </Paragraph>
                                                    <Title size="small">Who can create on the Peek?</Title>
                                                    <Paragraph>
                                                        Anyone with a passion for creating high-quality content can join the Peek and start earning. Whether you're a photographer, videographer, artist, or writer, our platform welcomes all creators.
                                                    </Paragraph>
                                                    <Title size="small">How much can I make on the Peek?</Title>
                                                    <Paragraph>
                                                        Earnings vary based on your subscriber base and the type of content you offer. Our platform offers the highest payouts in the industry, with creators keeping up to 85% of their earnings. Additional revenue can be made through tips, pay-per-view content, and exclusive offers.
                                                    </Paragraph>
                                                    <Title size="small">How long does it take to become a creator?</Title>
                                                    <Paragraph>
                                                        You can become a verified creator and start earning in less than 3 minutes. Simply sign up, verify your account, and start uploading your content. Our streamlined process ensures you can begin monetizing your work quickly.
                                                    </Paragraph>
                                                    <Title size="small">What kind of content can I share?</Title>
                                                    <Paragraph>
                                                        The Peek supports a wide range of content types, including photos, videos, audio files, and written stories. High-resolution formats such as RAW, DNG, and CR2 are supported to ensure the highest quality.
                                                    </Paragraph>
                                                    <Title size="small">How do I get paid?</Title>
                                                    <Paragraph>
                                                        Payments are processed monthly through your preferred payment method, including direct bank transfers, PayPal, and more. You can track your earnings in real-time through our dashboard.
                                                    </Paragraph>
                                                    <Title size="small">Can I interact with my subscribers?</Title>
                                                    <Paragraph>
                                                        Yes, the Peek offers various tools for creators to engage with their audience. You can send direct messages, host live sessions, and offer exclusive content to your subscribers.
                                                    </Paragraph>
                                                </Space>
                                            </Content>

                                        </Col>
                                    </Row>


                                </Space>
                            </Col>
                        </Row>
                    </div>
                </Content>
            </Page>
            <Footer />
        </Layout>
    );
}
