import React, {useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {Button, Card, Col, Content, DropDown, Layout, Page, Row, Space, Title} from '../layout/Content';
import {uploadChunk, UploadProgress} from "../../lib/FileUploadWithProgress";
import {Header} from "./Header";
import {Footer} from "./Footer";
// @ts-ignore
import MarkdownRenderer from 'react-markdown-renderer';
import {
    IconCheck,
    IconDoubleQuotationLeft,
    IconLeftSingleQuotationMark,
    IconLetterB,
    IconLetterI,
    IconQuotationRight
} from "../../lib/Icon";
import {InlineIcon, Tooltip} from "../layout/Defaults";
import {faComment, faPenToSquare, faNewspaper} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog, faHeading, faList, faCode, faQuoteRight, faPhotoFilm, faItalic, faBold} from "@fortawesome/free-solid-svg-icons";


interface MarkdownEditorProps {
    onChange: (markdown: string) => void;
    value?:string;
    preview?:boolean;
    onPreview?:(preview:boolean)=>void;
}

const MarkdownEditor: React.FC<MarkdownEditorProps> = ({ onChange, value="", preview = false, onPreview }) => {
    const [markdown, setMarkdown] = useState<string>(value);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const handleChange = (md: string) => {
        setMarkdown(md);
        if (onChange) {
            onChange(md);
        }
    };

    const insertAtCursor = (text: string) => {
        if (textareaRef.current) {
            const start = textareaRef.current.selectionStart;
            const end = textareaRef.current.selectionEnd;
            const before = markdown.substring(0, start);
            const after = markdown.substring(end);
            handleChange(before + text + after);
            textareaRef.current.focus();
            textareaRef.current.setSelectionRange(start + text.length, start + text.length);
        }
    };

    const wrapSelection = (before: string, after: string) => {
        if (textareaRef.current) {
            const start = textareaRef.current.selectionStart;
            const end = textareaRef.current.selectionEnd;
            const selectedText = markdown.substring(start, end);
            const beforeText = markdown.substring(0, start);
            const afterText = markdown.substring(end);
            handleChange(beforeText + before + selectedText + after + afterText);
            textareaRef.current.focus();
            textareaRef.current.setSelectionRange(start + before.length, end + before.length);
        }
    };

    const handleBold = () => {
        wrapSelection('**', '**');
    };

    const handleItalic = () => {
        wrapSelection('_', '_');
    };

    const handleHeader = () => {
        insertAtCursor('\n# Header 1\n');
    };

    const handleListItem = () => {
        insertAtCursor('\n- List item\n');
    };

    const handleCodeBlock = () => {
        insertAtCursor('\n```\nCode block\n```\n');
    };

    const handleBlockquote = () => {
        insertAtCursor('\n> Blockquote\n');
    };

    const handleImage = () => {
        insertAtCursor('![alt text](image-url)\n');
    };

    const handlePreview = () => {
        if (onPreview){
            onPreview(!preview);
        }
    };

    // @ts-ignore
    return (
        <div className={"markdown-editor Flex Column GapSm"}>
            <Space GapSm className="toolbar">
                <Tooltip message={"Preview"}><Button size="small" type={!preview?"default":"primary"} className={!preview?"ghost":""}  onClick={()=>{
                    if (onPreview){                    onPreview(true);}
                }}><FontAwesomeIcon size={"2x"} icon={faNewspaper}/></Button></Tooltip>
                <Tooltip message={"Edit"}><Button size="small" type={preview?"default":"primary"} className={preview?"ghost":""} onClick={()=>{
                    if (onPreview){      onPreview(false);}
                }}><FontAwesomeIcon size={"2x"} icon={faPenToSquare}/></Button></Tooltip>

                {!preview&&<Tooltip message={"Bold Text"}><Button type={preview?"disabled":"default"} size={"small"} onClick={handleBold}><FontAwesomeIcon fixedWidth icon={faBold} size={"2x"}/></Button></Tooltip>}
                {!preview&&<Tooltip message={"Emphasis Text"}><Button type={preview?"disabled":"default"} size={"small"} onClick={handleItalic}><FontAwesomeIcon fixedWidth icon={faItalic} size={"2x"}/></Button></Tooltip>}
                {!preview&&<Tooltip message={"Insert Heading"}><Button type={preview?"disabled":"default"} size={"small"} onClick={handleHeader}><FontAwesomeIcon fixedWidth icon={faHeading} size={"2x"}/></Button></Tooltip>}
                {!preview&&<Tooltip message={"List Item"}><Button type={preview?"disabled":"default"} size={"small"} onClick={handleListItem}><FontAwesomeIcon fixedWidth icon={faList} size={"2x"}/></Button></Tooltip>}
                {!preview&&<Tooltip message={"Code Block"}><Button type={preview?"disabled":"default"} size={"small"} onClick={handleCodeBlock}><FontAwesomeIcon fixedWidth icon={faCode} size={"2x"}/></Button></Tooltip>}
                {!preview&&<Tooltip message={"Blockquote"}><Button type={preview?"disabled":"default"} size={"small"} onClick={handleBlockquote}><FontAwesomeIcon fixedWidth icon={faQuoteRight} size={"2x"}/></Button></Tooltip>}
                {!preview&&<Tooltip message={"Insert Media"}><Button type={preview?"disabled":"default"} size={"small"} onClick={handleImage}><FontAwesomeIcon fixedWidth icon={faPhotoFilm} size={"2x"}/></Button></Tooltip>}
            </Space>
            <div style={{height:"100%"}}>
                {preview?<div className={"markdown-viewer"} style={{flex: "1 0 auto"}}><MarkdownRenderer markdown={markdown} options={{preset:"full"}} /></div>:
                <textarea
                ref={textareaRef}
                value={markdown}
                onChange={(e) => handleChange(e.target.value)}
                rows={10}
                cols={50}
            />}
            </div>
        </div>
    );
};

interface MarkdownViewerProps {
    markdown: string;
}

interface Transformation {
    regex: RegExp;
    transform: (match: string, ...args: string[]) => string;
}

const transformations: Transformation[] = [
    {
        regex: /^(#{1,6})\s*(.*)$/gm,
        transform: (match, p1, p2) => {
            const level = p1.length;
            return `</p><h${level}>${p2}</h${level}><p>`;
        }
    },
    {
        regex: /```([^]*?)```/g,
        transform: (match, p1) => `</p><pre><code>${p1}</code></pre><p>`
    },
    {
        regex: /\*\*(.*?)\*\*/g,
        transform: (match, p1) => `<strong>${p1}</strong>`
    },
    {
        regex: /_(.*?)_/g,
        transform: (match, p1) => `<em>${p1}</em>`
    },
    {
        regex: /^> (.*)$/gm,
        transform: (match, p1) => `</p><blockquote>${p1}</blockquote><p>`
    },
    {
        regex: /^(\*|\-|\+) (.*)$/gm,
        transform: (match, p1, p2) => `</p><ul><li>${p2}</li></ul><p>`
    },
    {
        regex: /^(\d+)\. (.*)$/gm,
        transform: (match, p1, p2) => `</p><ol><li>${p2}</li></ol><p>`
    },
    {
        regex: /\!\[(.*?)\]\((.*?)\)/g,
        transform: (match, p1, p2) => `<img src="${p2}" alt="${p1}" />`
    },
    {
        regex: /\[(.*?)\]\((.*?)\)/g,
        transform: (match, p1, p2) => `<a href="${p2}">${p1}</a>`
    },
    {
        regex: /(^|\n)(---|___|\*\*\*)($|\n)/g,
        transform: () => `</p><hr /><p>`
    },
];

const applyTransformations = (markdown: string): string => {
    let result = `${markdown}`;
    transformations.forEach(({ regex, transform }) => {
        result = result.replace(regex, transform);
        console.log(result);
    });
    return `<p>${result}</p>`;
};

const parsePseudoCode = (pseudoCode: string): React.ReactNode => {
    const htmlString = pseudoCode
        .replace(/<\/?p>/g, '\n')
        .replace(/<h([1-6])>(.*?)<\/h\1>/g, (_, level, content) => `\n<h${level}>${content}</h${level}>\n`)
        .replace(/<blockquote>(.*?)<\/blockquote>/g, '\n<blockquote>$1</blockquote>\n')
        .replace(/<pre><code>(.*?)<\/code><\/pre>/g, '\n<pre><code>$1</code></pre>\n')
        .replace(/<li>(.*?)<\/li>/g, '\n<li>$1</li>\n')
        .replace(/<ul>\s*\n<li>(.*?)<\/li>\s*\n<\/ul>/g, '<ul>\n<li>$1</li>\n</ul>')
        .replace(/<ol>\s*\n<li>(.*?)<\/li>\s*\n<\/ol>/g, '<ol>\n<li>$1</li>\n</ol>')
        .replace(/<strong>(.*?)<\/strong>/g, '<strong>$1</strong>')
        .replace(/<em>(.*?)<\/em>/g, '<em>$1</em>')
        .replace(/<a href="(.*?)">(.*?)<\/a>/g, '<a href="$1">$2</a>')
        .replace(/<img src="(.*?)" alt="(.*?)" \/>/g, '<img src="$1" alt="$2" />')
        .replace(/<hr \/>/g, '\n<hr />\n')

        // Merge sibling unordered lists
        .replace(/<\/ul>\s*<ul>/g, '')
        // Merge sibling ordered lists
        .replace(/<\/ol>\s*<ol>/g, '')
    ;

    return (
        <div dangerouslySetInnerHTML={{ __html: htmlString }} />
    );
};

const MarkdownViewer: React.FC<MarkdownViewerProps> = ({ markdown }) => {
    const pseudoCode = applyTransformations(markdown);
    return (
        <div className="markdown-viewer">
            {parsePseudoCode(pseudoCode)}
        </div>
    );
};

const MarkdownViewer2: React.FC<MarkdownViewerProps> = ({ markdown }) => {
    const parseMarkdown = (markdown: string): React.ReactNode => {
        const elements: React.ReactNode[] = [];
        const regex = /(```[\s\S]*?```|__[^_]+__|\*\*[^*]+\*\*|_[^_]+_|!\[[^\]]+\]\([^\)]+\)|\[([^\]]+)\]\(([^\)]+)\)|^(?:#{1,3} .+)$|^(?:> .+)$|^(?:-|\d+\.) .+|^(---|\*\*\*|___)$|.+)/gm;

        // @ts-ignore
        const matches = [...markdown.matchAll(regex)];
        console.log(matches);

        for (const match of matches) {
            const part = match[0].replace(/^\s/,"&nbsp;");
            let inlineReactElement;

            // Code block
            if (part.startsWith('```')) {
                const codeBlockText = part.slice(3, -3);
                elements.push(<pre key={elements.length}><code>{codeBlockText}</code></pre>);
                continue;
            }

            // Bold and Italics
            if (part.startsWith('**') && part.endsWith('**')) {
                inlineReactElement = <strong key={elements.length}>{part.slice(2, -2)}</strong>;
            } else if (part.startsWith('_') && part.endsWith('_')) {
                inlineReactElement = <em key={elements.length}>{part.slice(1, -1)}</em>;
            }

            // Images
            else if (part.startsWith('![') && part.includes('](')) {
                const altText = part.match(/!\[([^\]]+)\]/)?.[1];
                const url = part.match(/\(([^)]+)\)/)?.[1];
                inlineReactElement = <img key={elements.length} src={url} alt={altText} />;
            }

            // Links
            else if (part.startsWith('[') && part.includes('](')) {
                const linkText = part.match(/\[([^\]]+)\]/)?.[1];
                const url = part.match(/\(([^)]+)\)/)?.[1];
                inlineReactElement = <a key={elements.length} href={url}>{linkText}</a>;
            }

            // Headers
            else if (part.startsWith('# ')) {
                inlineReactElement = <h1 key={elements.length}>{part.replace(/^# /, '')}</h1>;
            } else if (part.startsWith('## ')) {
                inlineReactElement = <h2 key={elements.length}>{part.replace(/^## /, '')}</h2>;
            } else if (part.startsWith('### ')) {
                inlineReactElement = <h3 key={elements.length}>{part.replace(/^### /, '')}</h3>;
            }

            // Blockquotes
            else if (part.startsWith('> ')) {
                inlineReactElement = <blockquote key={elements.length}>{part.replace(/^> /, '')}</blockquote>;
            }

            // Horizontal rules
            else if (part === '---' || part === '***' || part === '___') {
                inlineReactElement = <hr key={elements.length} />;
            }

            // Lists
            else if (part.match(/^(?:-|\d+\.) /)) {
                const isOrdered = part.match(/^\d+\./);
                const listItems = part.split('\n').map((item:string, index:number) => (
                    <li key={index}>{item.replace(/^(?:-|\d+\.) /, '')}</li>
                ));
                if (isOrdered) {
                    inlineReactElement = <ol key={elements.length}>{listItems}</ol>;
                } else {
                    inlineReactElement = <ul key={elements.length}>{listItems}</ul>;
                }
            }

            // Default paragraph for unrecognized elements
            else {
                inlineReactElement = <span key={elements.length}>{part}</span>;
            }

            if (inlineReactElement) {
                elements.push(inlineReactElement);
            }
        }

        return elements;
    };

    return <div className="markdown-viewer">{parseMarkdown(markdown)}</div>;
};


interface PostFormProps {}


const PostForm: React.FC<PostFormProps> = () => {
    const [title, setTitle] = useState<string>('');
    const [content, setContent] = useState<string>('');
    const navigate = useNavigate();

    const [preview, setPreview] = useState<boolean>(false);


    const [markdown, setMarkdown] = useState<string>(`# Placeholder Blog Post



Welcome to the **Placeholder Blog Post**! This is a sample markdown file that you can use to create and format your blog posts.

---

## Introduction

This is the introduction to your blog post. Provide a brief overview of the content and what readers can expect.

## Content Section

### Subheading

Write your main content here. Share information, insights, or stories that are relevant to your blog post.

## Lists

### Unordered List

- Item 1
- Item 2
  - Subitem 2.1
  - Subitem 2.2
- Item 3

### Ordered List

1. First item
2. Second item
   1. Subitem 2.1
   2. Subitem 2.2
3. Third item

## Images

To include an image, use the following syntax:

![Alt text](https://peek.blog/logo192.png "Optional Title")

## Links

Here’s an example of a [link](https://www.example.com).

## Blockquotes

> This is a blockquote. You can use blockquotes to highlight important information or quotes from other sources.

## Horizontal Rules

You can use horizontal rules to separate sections:

---

## Conclusion

Summarize the key points and leave readers with a final thought or call to action.

---

Thank you for reading! Feel free to share your thoughts in the comments below.
`);


    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        //e.preventDefault();

        const postData = {
            title,
            content:markdown,
            // owner: 'owner-uuid', // Replace this with actual owner UUID
        };

        console.log(postData);

        try {
            const response = await fetch('https://api.peek.blog/post', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include', // This tells the browser to include cookies with the request
                body: JSON.stringify(postData),
            });

            if (!response.ok) {
                throw new Error('Failed to create post');
            }

            const result = await response.json();
            console.log('Post created successfully:', result);
            setTitle('');
            setContent('');
            navigate("/p/" + result.results[0].uuid);
        } catch (error) {
            console.error('Error creating post:', error);
        }
    };

    return (<Layout className="landing-page">
        <Header/>
        <Page Grow>
            <Content style={{height: "100%"}}>
                <div className={"limits wide pad"} style={{height:"100%"}}>
                    <Row Gap Fill style={{height:"100%"}}>
                        <Col xs={24} md={18}>
                            <MarkdownEditor value={markdown} onChange={setMarkdown} preview={preview} onPreview={(preview)=>{
                                setPreview(preview);
                            }}/>
                        </Col>
                        <Col xs={24} md={6}>
                            <Card Pad>
                            <Space direction={"vertical"} Gap>
                                <Space direction={"vertical"} GapSm>
                                    <label htmlFor="title">Title</label>
                                    <input
                                        type="text"
                                        id="title"
                                        value={title}
                                        placeholder={"Post Title"}
                                        onChange={handleTitleChange}
                                        required
                                    />

                                </Space>

                                <Space direction={"vertical"} GapSm>
                                    <Space direction={"horizontal"} style={{flexWrap:"none"}} GapSm align={"center"}>
                                        {preview&&<span>Preview Mode Enabled</span>}
                                        <button className={`Grow ${preview?"default":"primary"}`} onClick={(e:any)=>{
                                            setPreview(!preview);
                                    }} >{preview?"Edit Post":"Preview before Saving"}</button>
                                    </Space>
                                <Button type={preview?"primary":"disabled"} onClick={(e:any)=>{
                                    if (preview) {
                                        handleSubmit(e);
                                    }
                                }} >Save Post</Button>
                                </Space>
                            </Space>
                            </Card>
                        </Col>

                    </Row>
                </div>
            </Content>
        </Page>
        <Footer/>
    </Layout>);
};

export default PostForm;
