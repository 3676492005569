import {
    Col,
    Content,
    Layout,
    Page,
    Row,
    Space,
    Title,
    Divider,
    Link,
} from "../layout/Content";
import React, { useEffect, useState } from "react";
import { Footer } from "../part/Footer";
import { Header } from "../part/Header";
import { useNavigate } from "react-router-dom";
import { Announcement, Empty } from "../layout/Defaults";
import { useUserProfileContext } from "../../lib/UserContext";
import MediaItemComponent from "../part/MediaItemComponent";


export function Gallery() {
    const navigate = useNavigate();
    const { queryUserProfile, getUserProfile, hasQueriedUserProfile } = useUserProfileContext();

    const [loading, setLoading] = useState<boolean>(false);
    const [announce, setAnnounce] = useState<boolean>(true);
    const [media, setMedia] = useState<any[]>([]);
    const [selectedTab, setSelectedTab] = useState<string>("recent");

    const fetchMedia = async (tab: string) => {
        setLoading(true);
        try {
            const userProfile = getUserProfile();
            const userUuid = userProfile ? userProfile.uuid : null;
            const filter = (tab === "discover" || tab === "explore" || tab === "following") ? "" : `${userUuid}`;

            const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/media?filter=${filter}`, {
                method: 'GET',
                credentials: 'include',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const data = await response.json();
                setMedia(data.results);
            } else {
                console.error('Failed to fetch media');
            }
        } catch (error) {
            console.error('Error fetching media:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (hasQueriedUserProfile()) {
            fetchMedia(selectedTab);
        }
    }, [getUserProfile(), hasQueriedUserProfile(), selectedTab]);

    const handleTabClick = (tab: string) => {
        setSelectedTab(tab);
    };

    return (
        <Layout className="landing-page">
            <Header />
            <Page Grow>
                <Content style={{ height: "100%" }}>
                    <Row Fill>
                        <Col xs={24} className={"Flex"}>
                            <div className={"limits wide padH"}>
                                <Space direction={"vertical"} Gap>
                                    <Row Gap>

                                        {(!getUserProfile() && hasQueriedUserProfile() && announce) && (
                                            <Col xs={24}>
                                                <Announcement onClose={() => setAnnounce(false)}>
                                                    <span style={{ display: "inline-block" }}>
                                                        This feed is limited to <strong>Safe-for-work</strong>, <strong>Standard</strong> Quality, and <strong>Free</strong> Content only.
                                                    </span>
                                                    <span style={{ display: "inline-block" }}>
                                                        <Link href={"/signin"}>Sign in</Link> to unlock everything.
                                                    </span>
                                                </Announcement>
                                            </Col>
                                        )}

                                        <Col xs={24}>
                                            <Row Gap style={{ width: "100%" }}>
                                                <Col xs={24} sm={4}>
                                                    <Title size={3}>The Gallery</Title>
                                                    <ul>
                                                        <li onClick={() => handleTabClick('discover')} className={selectedTab === "discover" ? "active" : ""}>Discover</li>
                                                        <li onClick={() => handleTabClick('explore')} className={selectedTab === "explore" ? "active" : ""}>Explore</li>
                                                        <li onClick={() => handleTabClick('following')} className={selectedTab === "following" ? "active" : ""}>Following</li>
                                                    </ul>
                                                    <Divider />
                                                    <Title size={3}>My Content</Title>
                                                    <ul>
                                                        <li onClick={() => handleTabClick('recent')} className={selectedTab === "recent" ? "active" : ""}>Recent</li>
                                                        <li onClick={() => handleTabClick('favorites')} className={selectedTab === "favorites" ? "active" : ""}>Favorites</li>
                                                        <li onClick={() => handleTabClick('archive')} className={selectedTab === "archive" ? "active" : ""}>Archive</li>
                                                    </ul>
                                                </Col>

                                                <Col xs={24} sm={20}>
                                                    <Row Gap style={{ width: "100%" }}>
                                                        {loading ? (
                                                            <p>Loading...</p>
                                                        ) : media.length > 0 ? (
                                                            media.map((item) => (
                                                                <MediaItemComponent key={item.uuid} item={item} />
                                                            ))
                                                        ) : (
                                                            <p>No Media available.</p>
                                                        )}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Space>
                            </div>
                        </Col>
                    </Row>
                </Content>
            </Page>
            <Footer />
        </Layout>
    );
}
