import React, {useState, useEffect, useRef} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {Button, Link, Space} from "../layout/Content";

interface Option<T> {
    element: React.ReactNode;
    value: T;
}

interface SelectProps<T> {
    options: Option<T>[];
    defaultValue?: T;
    placeholder?: React.ReactNode | string;
    onSelected: (value: T) => void;
}

export const Select: React.FC<SelectProps<any>> = ({
                                                       options,
                                                       defaultValue,
                                                       placeholder,
                                                       onSelected
                                                   }) => {
    const [isActive, setIsActive] = useState(false);
    const [selectedValue, setSelectedValue] = useState(defaultValue);
    const dropdownNode = useRef<HTMLDivElement>(null);

    const toggleMenu = () => {
        setIsActive(current => !current);
    };

    const selectOption = (value: any) => {
        setSelectedValue(value);
        onSelected(value);
        setIsActive(false);
    };

    const closeMenu = (event: MouseEvent) => {
        if (dropdownNode.current && !dropdownNode.current.contains(event.target as Node)) {
            setIsActive(false);
        }
    };

    useEffect(() => {

        console.log('initial selection value', defaultValue,",", selectedValue);

        document.addEventListener("mousedown", closeMenu);

        return () => {
            document.removeEventListener("mousedown", closeMenu);
        };
    }, []);

    useEffect(() => {

        console.log('default value updated',defaultValue, selectedValue);
        setSelectedValue(defaultValue||undefined);

    }, [defaultValue]);

    return (
        <div ref={dropdownNode} className="dropdown-container">
            <Button onClick={toggleMenu} className="menu-button" type={"ghost"}>
                <Space align={"center"} Gap>{(typeof selectedValue === "string" && selectedValue !== "")
                    ? (options.find(option => option.value === selectedValue)?.element)
                    : (placeholder || <span>Select an option</span>)}
                    <FontAwesomeIcon icon={isActive ? faAngleUp : faAngleDown}/>
                </Space></Button>

                <div className={`dropdown-inner left ${isActive ? "active" : ""}`}>
                    <Space direction={"vertical"} GapSm>
                    {options.map(option => (
                        <Link href={"#"} key={String(option.value)} className="dropdown-item"
                             onClick={() => selectOption(option.value)}>
                            {option.element}
                        </Link>
                    ))}
                    </Space>
                </div>

        </div>
    );
};