import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Col, Content, Layout, Link, Page, Row, Space, Title} from "../layout/Content";
import {Empty, InlineImage, Loading, Result} from '../layout/Defaults';
import {convertUTCToLocalDatetime} from "../../lib/Time";
import {Header} from "../part/Header";
import {Footer} from "../part/Footer";
// @ts-ignore
import MarkdownRenderer from 'react-markdown-renderer';
import LikeButton from "../part/LikeButton";
import CommentsBar from "../part/CommentsBar";
import {getAvatarURLForUser} from "../../lib/Icon";

// Function to detect the language of a given code snippet
const detectLanguage = (code: string): string => {
    //const result = hljs.highlightAuto(code);
    return 'plaintext';//result.language as string;
};

const PostViewer: React.FC = () => {
    const {uuid} = useParams<{ uuid: string }>();
    const [post, setPost] = useState<any | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [hiddenBlocks, setHiddenBlocks] = useState<Record<string, boolean>>({});
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();


    useEffect(() => {
        // Fetch the post from the API
        const fetchPost = async () => {
            try {
                const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/post/${uuid}`, {
                    method: 'GET',
                    credentials: 'include',
                    mode: 'cors', // This needs to be 'cors' to follow CORS rules
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data: any = await response.json();
                console.log(data);
                setPost(data.results[0]);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch post.');
                setLoading(false);
                console.error(err);
            }
        };
        fetchPost();
    }, [uuid]);

    const toggleHidden = (blockId: string) => {
        setHiddenBlocks(prev => ({
            ...prev,
            [blockId]: !prev[blockId]
        }));
    };

    if (loading) return <Loading/>;
    if (error) return <Content><Result
        title={<strong>Post Unavailable</strong>}
        subTitle={error}
        status="error"
    /></Content>;
    if (!post) return <div>No post found.</div>;
    if (!post.owner || !post.owner.uuid) {
        return <h1>unable to complete uuid request</h1>;
    }

    // Render the post details and iterate over blocks
    return (<Layout className="landing-page">
        <Header/>
        <Page Grow>
            <Content style={{height: "100%"}}>
                <div className={"limits pad"}>
                    <Row justify="center" Gap>
                        <Col xs={24} md={18}>
                            <Content>

                                <Title Large>{post.title}</Title>
                                <div className={"markdown-viewer"}><MarkdownRenderer markdown={post.content} options={{preset:"full"}} /></div>
                                <Row>
                                    {post.blocks&&post.blocks.map((block: any) => (
                                        <Col id={block._id} xs={12}>

                                            {block.type === 'text' && (
                                                <div>
                                                    <p>{block.content}</p>
                                                </div>
                                            )}
                                            {block.type === 'code' && (
                                                <pre><code>{(block.content)}</code></pre>
                                            )}
                                            {block.type === 'image' && (
                                                <div>
                                                    <img
                                                        src={`//${process.env.REACT_APP_ASSET_ORIGIN}/media/${hiddenBlocks[block._id] ? 'blurred-' : ''}${block.content}.jpeg`}
                                                        alt="block content" style={{maxWidth: '100%', marginTop: '8px'}}/>
                                                </div>
                                            )}
                                        </Col>

                                    ))}
                                </Row>

                            </Content>
                        </Col>
                        <Col xs={24} md={6}>
                            <Content>
                                <Space direction={"vertical"} Gap>
                                    <p><strong>Created</strong><br/>{convertUTCToLocalDatetime(post.createdAt)}</p>
                                    {(post.updatedAt !== post.createdAt) &&
                                        <p><strong>Last updated</strong><br/>{convertUTCToLocalDatetime(post.updatedAt)}</p>}
                                    <p><strong>Written by</strong><br/><InlineImage src={getAvatarURLForUser(post.owner)}/> <Link
                                        href={`/u/${post.owner.uuid}`}>{post.owner.attributes.displayName}</Link></p>
                                    <p>
                                        <LikeButton uuid={post.uuid} />
                                    </p>
                                    <p>
                                        <CommentsBar uuid={post.uuid} />
                                    </p>

                                </Space>
                            </Content>
                        </Col>
                    </Row>
                </div>
            </Content>
        </Page>
        <Footer/>
    </Layout>)

};

export default PostViewer;
