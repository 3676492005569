import React from "react";
import {
    Col,
    Content,
    Layout,
    Page,
    Row,
    Paragraph,
    Space,
    Title,
    Divider,
    Button, Card, Input, Link
} from "../layout/Content";
import {Footer} from "../part/Footer";
import Badge from "../part/Badge";
import {Header} from "../part/Header";
import {Trademark} from "../part/Trademark";

export function Features() {
    return (
        <Layout className="features-overview-page">
            <Header/>
            <Page Grow>
                <Content style={{height: "100%"}}>
                    <div className={"limits pad"}>
                        <Row Fill>
                            <Col xs={24}>
                                <Space direction={"vertical"} Gap>
                                    <Row align={"center"} Gap>
                                        <Col xs={24}>
                                            <Content>
                                                <Title Large><span
                                                    className={"heavy"}>the Peek</span><Trademark/> Feature
                                                    Overview</Title>
                                                <Paragraph>
                                                    Discover the powerful features of Peek, designed to provide an
                                                    immersive
                                                    experience for both viewers and creators.
                                                </Paragraph>
                                            </Content>
                                        </Col>
                                    </Row>

                                    {/* High-Resolution Media Section */}
                                    <Row Gap>
                                        <Col xs={24} md={12}>
                                            <Card Pad Full>
                                                <Content>
                                                    <Title>Ultra High Resolution Media</Title>
                                                    <Paragraph>
                                                        Upload and view ultra-high resolution photos, videos, and sound
                                                        files. Our platform supports RAW, DNG, and CR2 file formats to
                                                        ensure you get the highest quality.
                                                    </Paragraph>
                                                </Content>
                                            </Card>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Card Pad Full>
                                                <Content>
                                                    <Title>Community Engagement</Title>
                                                    <Paragraph>
                                                        Connect with fellow creators, explore curated content, and
                                                        engage in
                                                        meaningful discussions. Peek fosters a vibrant and interactive
                                                        community.
                                                    </Paragraph>
                                                </Content>
                                            </Card>
                                        </Col>
                                    </Row>

                                    {/* AI Tools and Subscription Section */}
                                    <Row Gap>
                                        <Col xs={24} md={12}>
                                            <Card Pad Full>
                                                <Content>
                                                    <Title>AI Photo Tools</Title>
                                                    <Paragraph>
                                                        Utilize advanced AI tools to enhance your photos, add effects,
                                                        and
                                                        more. Our AI tools are designed to help you get the most out of
                                                        your
                                                        media.
                                                    </Paragraph>
                                                </Content>
                                            </Card>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Card Pad Full>
                                                <Content>
                                                    <Title>Content Subscriptions</Title>
                                                    <Paragraph>
                                                        Subscribe to your favorite creators and get access to exclusive
                                                        content. Our subscription model ensures that creators are
                                                        rewarded
                                                        for their work.
                                                    </Paragraph>
                                                </Content>
                                            </Card>
                                        </Col>
                                    </Row>

                                    {/* Call to Action Section */}
                                    <Row>
                                        <Col xs={24}>
                                            <Content>
                                                <Space direction={"vertical"} GapSm>
                                                    <Title>Join the fastest growing community of Creators</Title>
                                                    <Button type={"disabled"}>Coming Soon</Button>
                                                    <Paragraph>
                                                        Are you a Content Creator? Join our <Link href={"/partner"}>Create a Peek</Link> Program!
                                                    </Paragraph>
                                                </Space>
                                            </Content>
                                        </Col>
                                    </Row>


                                    {/* FAQ Section */}
                                    <Row>
                                        <Col xs={24}>
                                            <Content>
                                                <Space direction={"vertical"} GapSm>
                                                    <Space align={"center"} Gap>
                                                        <Title Large>Frequently Asked Questions</Title>
                                                        <Divider/>
                                                    </Space>
                                                    <Title size="small">What is the Peek?</Title>
                                                    <Paragraph>
                                                        The Peek is an online platform where you can discover and enjoy high-resolution media content created by talented individuals from around the world. It's a space to explore photos, videos, audio files, and written stories in stunning detail.
                                                    </Paragraph>
                                                    <Title size="small">How do I sign up?</Title>
                                                    <Paragraph>
                                                        Signing up is quick and easy. Simply visit our sign-up page, enter your details, and you'll be ready to start exploring and subscribing to your favorite creators in no time.
                                                    </Paragraph>
                                                    <Title size="small">What kind of content is available?</Title>
                                                    <Paragraph>
                                                        The Peek offers a diverse range of content, including photography, videography, music, and written stories. You can find everything from artistic portraits and landscape photos to immersive videos and compelling narratives.
                                                    </Paragraph>
                                                    <Title size="small">How do I support creators?</Title>
                                                    <Paragraph>
                                                        You can support creators by subscribing to their content, purchasing pay-per-view items, and leaving tips. Subscriptions give you access to exclusive content and help creators continue to produce high-quality work.
                                                    </Paragraph>
                                                    <Title size="small">Is the content high quality?</Title>
                                                    <Paragraph>
                                                        Absolutely! The Peek supports high-resolution formats such as RAW, DNG, and CR2, ensuring that you experience the highest quality media. Our platform is designed to showcase content in the best possible way.
                                                    </Paragraph>
                                                    <Title size="small">Can I interact with creators?</Title>
                                                    <Paragraph>
                                                        Yes, you can interact with creators through direct messages, comments, and live sessions. Many creators also offer exclusive content and personalized interactions for their subscribers.
                                                    </Paragraph>
                                                    <Title size="small">Is my payment information secure?</Title>
                                                    <Paragraph>
                                                        Yes, we use industry-standard security measures to protect your payment information. All transactions are securely processed, and your personal data is kept confidential.
                                                    </Paragraph>
                                                    <Title size="small">What if I need help or have questions?</Title>
                                                    <Paragraph>
                                                        Our support team is available 24/7 to assist you with any questions or issues you may have. You can reach out to us through our support page or contact us directly via email.
                                                    </Paragraph>
                                                </Space>
                                            </Content>

                                        </Col>
                                    </Row>


                                </Space>
                            </Col>
                        </Row>


                    </div>
                </Content>
            </Page>
            <Footer/>
        </Layout>
    );
}
