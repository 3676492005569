import React, { useState, useEffect } from 'react';
import {Button, Input, Link, Small, Space, Title, Text} from "../layout/Content";
import {InlineImage, Skeleton, Tooltip} from "../layout/Defaults";
import {convertUTCToLocalDatetime} from "../../lib/Time";
import {useUserProfileContext} from "../../lib/UserContext";

interface Comment {
    uuid: string;
    content: string;
    owner?:any;
    createdAt: string;
}

interface CommentsBarProps {
    uuid: string;
}

const CommentsBar: React.FC<CommentsBarProps> = ({ uuid }) => {
    const [comments, setComments] = useState<Comment[]>([]);
    const [newComment, setNewComment] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const { queryUserProfile, getUserProfile, hasQueriedUserProfile } = useUserProfileContext();

    const fetchComments = async () => {
        try {
            const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/post/comments/${uuid}`, {
                method: 'GET',
                credentials: 'include',
                mode: 'cors', // This needs to be 'cors' to follow CORS rules
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const data = await response.json();
                console.log('got comments', data);

                setComments(data);
            } else {
                console.error('Failed to fetch comments');
            }
        } catch (error) {
            console.error('Error fetching comments:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleAddComment = async () => {
        if (!newComment.trim()) return;

        try {
            const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/post/comments/${uuid}`, {
                method: 'POST',
                credentials: 'include',
                mode: 'cors', // This needs to be 'cors' to follow CORS rules
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ content: newComment })
            });

            if (response.ok) {
                const addedComment = await response.json();
               // setComments([...comments, addedComment]);
                setNewComment('');
                await fetchComments();
            } else {
                console.error('Failed to add comment');
            }
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    };

    useEffect(() => {
        fetchComments();
    }, [uuid]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Space direction="vertical" Gap>
                <h3>Comments</h3>
                {comments.length > 0 ? (

                    comments.map((comment:Comment) => (
                        <div key={comment.uuid}>
                            <p><InlineImage src={"/logo192.png"}/> <Tooltip message={`@${(comment.owner.attributes.username)}`}><Link
                                href={`/u/${comment.owner.uuid}`}>{comment.owner.attributes.displayName}</Link></Tooltip> says</p>
                            <p>{comment.content}</p>
                            <Small>{convertUTCToLocalDatetime(comment.createdAt)}</Small>
                        </div>
                    ))
                ) : (
                    <p>No comments yet. Be the first to comment!</p>
                )}
                {getUserProfile() ? (
                    <Space direction="vertical" Gap>
                        <h3>Post New Comment</h3>
                        <textarea
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            placeholder="Add a comment..."
                        />
                        <Button onClick={handleAddComment} type="primary">Post Comment</Button>
                    </Space>
                ):(<p>Sign in to Post a Comment!</p>)}
            </Space>
        </div>
    );

};

export default CommentsBar;
