import {
    Col,
    Content,
    Layout,
    Page,
    Row,
    Paragraph,
    Space,
    Title,
    Divider,
    Button, Card, Input, Link
} from "../layout/Content";
import React from "react";
import {Footer} from "../part/Footer";
import Badge from "../part/Badge";
import {Header} from "../part/Header";
import {Trademark} from "../part/Trademark";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faLock, faPenNib, faThList, faUsers} from "@fortawesome/free-solid-svg-icons";
import {faComments} from "@fortawesome/free-regular-svg-icons";

export function Landing() {
    return (<Layout className="landing-page">
        <Header/>
        <Page Grow>
            <Content style={{height: "100%"}}>
                <Row style={{height: "100%"}}>

                    <Col xs={24} className={"flex center"}>
                        <div className={"limits wide pad"}>
                            <Space direction={"vertical"} Gap>


                                <Row align={"center"} Gap>
                                    <Col xs={24} md={8}>
                                        <Content>
                                            <Space direction={"vertical"} GapSm>
                                            <Title>A photo sharing and blogging platform built for the future.</Title>
                                            <small><Space NoWrap GapSm align={"center"}><FontAwesomeIcon fixedWidth icon={faCamera} /> <span>Ultra Detailed 48MP Photos & 4k Videos</span></Space></small>
                                            <small><Space NoWrap GapSm align={"center"}><FontAwesomeIcon fixedWidth icon={faLock} /> <span>Secured Content & Private Marketplace</span></Space></small>
                                            <small><Space NoWrap GapSm align={"center"}><FontAwesomeIcon fixedWidth icon={faPenNib} /> <span>Uncover the Deep, Dark, and Secret Details</span></Space></small>
                                            </Space>
                                        </Content>
                                    </Col>
                                    <Col xs={24} md={8}>

                                        <Card Pad>
                                            <Space direction={"vertical"}>

                                                <Space Gap justify={"space-between"} align={"center"}>

                                                    <Space direction={"vertical"}>
                                                        <Title Muted>the</Title>
                                                        <Title Large>Peek<Trademark/></Title>
                                                    </Space>

                                                    <Space direction={"vertical"}>
                                                        <Title>coming soon</Title>
                                                        <Title Muted>10.22.2024</Title>
                                                    </Space>


                                                </Space>
                                                <Divider/>
                                                <Space direction={"vertical"} Gap>
                                                    <Title>Get Notified for Early Access!</Title>
                                                    <Space Gap>
                                                        <Input type={"email"} placeholder={"email@address.com"} Grow/>
                                                        <Button type={"primary"}>submit</Button>
                                                    </Space>
                                                </Space>

                                            </Space>
                                        </Card>

                                    </Col>
                                    <Col xs={24} md={8}>
                                        <Content>

                                            <Space direction={"vertical"} GapSm>
                                                <Title>Join a growing community and share your story, your way.</Title>
                                                <small><Space NoWrap GapSm align={"center"}><FontAwesomeIcon fixedWidth icon={faUsers} /> <span>Build Bonds Connect with Fellow Creators</span></Space></small>
                                                <small><Space NoWrap GapSm align={"center"}><FontAwesomeIcon fixedWidth icon={faThList} /> <span>Discover Gems Explore Curated Content</span></Space></small>
                                                <small><Space NoWrap GapSm align={"center"}><FontAwesomeIcon fixedWidth icon={faComments} /> <span>Dive Deep Engage in Meaningful Discussions</span></Space></small>
                                            </Space>

                                        </Content>
                                    </Col>
                                </Row>


                                <Row>
                                    <Col xs={24}>
                                        <Space direction={"vertical"} Gap>
                                            <Row Gap><Col xs={24} md={24}>
                                                    <div className="Hero flex center"
                                                         style={{backgroundImage: "url(/hero03.jpeg)"}}>
                                                        <Link href={"/peek"} span>
                                                        <Button type={"primary"}>Take a Peek</Button>
                                                        </Link>
                                                    </div>
                                            </Col>
                                                {/*<Col xs={24} md={12}>
                                                <Link href={"/partner"}>
                                                    <div className="Hero flex center"
                                                         style={{backgroundImage: "url(/hero01.jpeg)"}}>
                                                        <Badge color="red"
                                                               data={<span><strong>18+</strong> ONLY</span>}>
                                                            <Button type={"ghost"}>Peek Megapixel Girls</Button>
                                                        </Badge>
                                                    </div>
                                                </Link>
                                            </Col>*/}
                                            </Row>
                                            <Space justify={"end"}>
                                                <Paragraph>
                                                    Are you a Content Creator? Join our <Link href={"/partner"}>Peek
                                                    Partners</Link> Program.
                                                </Paragraph>
                                            </Space>
                                        </Space>
                                    </Col>
                                </Row>


                            </Space>
                        </div>
                    </Col>
                </Row>

            </Content>
        </Page>
        <Footer/>
    </Layout>)
}