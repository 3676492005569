import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Col,
    Content,
    Layout,
    Page,
    Row,
    Space,
    Title,
    Divider,
    Input,
    Paragraph,
} from '../layout/Content';
import { Drawer, Modal } from '../layout/Defaults';

interface UserAttribute {
    displayName: string;
    username: string;
}

interface ProvidedAttribute {
    provider: string;
    region?: string;
    profilePictureUrl?: string;
    firstName?: string;
    lastName?: string;
    locale?: string;
    email?: string;
}

interface UserProfile {
    _id: string;
    uuid: string;
    googleId?: string;
    attributes: UserAttribute;
    provided: ProvidedAttribute;
    createdAt: string;
    __v: number;
}

interface QueryResult<T> {
    results: T[];
    query: {
        total_items: number;
        page: {
            index: number;
            size: number;
        };
        timing: number;
    };
}

const API_URL = '//api.peek.blog/user';

const UserManager: React.FC = () => {
    const [users, setUsers] = useState<UserProfile[]>([]);
    const [filter, setFilter] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [pageSize] = useState<number>(5); // Fixed page size
    const [editUser, setEditUser] = useState<UserProfile | null>(null);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const fetchUsers = async (filter: string, pageIndex: number, pageSize: number): Promise<void> => {
        setLoading(true);
        try {
            const response = await fetch(`${API_URL}?filter=${filter}&page=${pageIndex}&size=${pageSize}`);
            const result: QueryResult<UserProfile> = await response.json();
            setUsers(result.results);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id: string): Promise<void> => {
        try {
            const response = await fetch(`${API_URL}/${id}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                fetchUsers(filter, pageIndex, pageSize);
            }
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    const handleUpdate = async (user: UserProfile): Promise<void> => {
        try {
            const response = await fetch(`${API_URL}/${user._id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include', // This tells the browser to include cookies with the request
                body: JSON.stringify(user),
            });
            if (response.ok) {
                fetchUsers(filter, pageIndex, pageSize);
            }
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    useEffect(() => {
        fetchUsers(filter, pageIndex, pageSize);
    }, [filter, pageIndex]);

    const openModal = (user: UserProfile) => {
        setEditUser(user);
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setEditUser(null);
    };

    const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (editUser) {
            setEditUser({ ...editUser, [e.target.name]: e.target.value });
        }
    };

    const handleEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (editUser) {
            await handleUpdate(editUser);
            closeModal();
        }
    };

    return (
        <Layout className="user-manager">
            <Page Grow>
                <Content style={{ height: '100%' }}>
                    <div className="limits pad">
                        <Row>
                            <Col xs={24}>
                                <Space direction="vertical" Gap>
                                    <Title Large>User Manager</Title>
                                    <Divider />
                                    <Input
                                        type="text"
                                       // value={filter}
                                       // onChange={(e) => setFilter(e.target.value)}
                                        placeholder="Filter users"
                                    />
                                    <Divider />
                                    {loading ? (
                                        <Paragraph>Loading...</Paragraph>
                                    ) : (
                                        users.map((user) => (
                                            <Card key={user._id}>
                                                <Space direction="horizontal" Gap>
                                                    <img src={user.provided.profilePictureUrl} alt="Profile" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                                                    <div>
                                                        <span>{user.attributes.displayName}</span>
                                                        <span>{user.attributes.username}</span>
                                                        <span>{user.provided.email}</span>
                                                    </div>
                                                    <Button onClick={() => handleDelete(user._id)}>Delete</Button>
                                                    <Button onClick={() => openModal(user)}>Edit</Button>
                                                </Space>
                                            </Card>
                                        ))
                                    )}
                                    <Space direction="horizontal" Gap>
                                        <Button onClick={() => setPageIndex(Math.max(0, pageIndex - 1))}>Previous</Button>
                                        <Button onClick={() => setPageIndex(pageIndex + 1)}>Next</Button>
                                    </Space>
                                </Space>
                            </Col>
                        </Row>
                    </div>
                </Content>
            </Page>

            {editUser && (
                <Modal
                    visible={isModalVisible}
                    title="Edit User"
                    content={
                        <form onSubmit={handleEditSubmit}>
                            <Space direction="vertical" Gap>
                                <Input
                                    type="text"
                                  //  name="displayName"
                                   // value={editUser.attributes.displayName}
                                    //onChange={handleEditChange}
                                    placeholder="Display Name"
                                />
                                <Input
                                    type="text"
                                  //  name="username"
                                 //   value={editUser.attributes.username}
                                   // onChange={handleEditChange}
                                    placeholder="Username"
                                />
                                <Input
                                    type="text"
                                  //  name="email"
                                  //  value={editUser.provided.email}
                                   // onChange={handleEditChange}
                                    placeholder="Email"
                                />
                                <Button type="primary">Save</Button>
                                <Button type="default" onClick={closeModal}>Cancel</Button>
                            </Space>
                        </form>
                    }
                    onClose={closeModal}
                />
            )}

        </Layout>
    );
};

export default UserManager;
